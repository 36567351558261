import React from "react";

class Erroboundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.log("error: " + error + "   errorInfo: " + JSON.stringify(errorInfo));
  }
  render() {
    if (this.state.hasError)
      return (
        <div
          style={{
            width: 300,
            marginTop: 40,
            margin: "auto",
          }}
        >
          <h2>Something went wrong: {this.state.hasError}</h2>
          {/* <h4> :( Seems your server is down</h4> */}
          <a
            href="/"
            style={{
              textDecoration: "none",
              border: "solid 2px green",
              padding: 6,
              borderRadius: 5,
              marginTop: 40,
              color: "green",
            }}
          >
            Please Reload Page
          </a>
        </div>
      );
    return this.props.children;
  }
}
export default Erroboundary;
