import React from "react";
import Header from "./header";
import CooperatorCore from "./cooperator-core";
import { useParams } from "react-router-dom";
export default function CoorperatorEdit() {
  const params = useParams();

  return (
    <React.Fragment>
      <Header user="admin" />
      <div>
        <h3>:: Edit Cooperator</h3>
        <CooperatorCore operaton="edit" id={params.id} />
      </div>
    </React.Fragment>
  );
}
