import React from "react";
import EditUser from "./editUser";
import useFetch from "./use-fetch";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Dialog from "./dialog";
import useDialog from "./useDialog";

export default function EditUserUser() {
  const { userId } = useFetch();
  const [openDialog, handleOpen, handleClose] = useDialog();

  const icon = (
    <small>
      <AccountCircle fontSize="small" sx={{ fontWeight: "bold" }} />
      &nbsp;&nbsp;Edit Account
    </small>
  );
  return (
    <React.Fragment>
      <Dialog
        icon={icon}
        user={true}
        styleVariant={{ width: 500 }}
        openDialog={openDialog}
        handleOpen={handleOpen}
        handleClose={handleClose}
      >
        <EditUser id={userId} caller="header" />
      </Dialog>
    </React.Fragment>
  );
}
