import * as React from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import LightTooltip from "./light-tooltip";
import Zoom from "@mui/material/Zoom";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-tap-highlight-color: transparent;
`;

export default function Dialog(props) {
  const iconn = (
    <IconButton
      color="default"
      size="small"
      aria-label="add"
      onClick={props.handleOpen}
    >
      {props.icon}
    </IconButton>
  );
  const iconTooltip = (
    <LightTooltip TransitionComponent={Zoom} title="Menu" placement="right">
      {iconn}
    </LightTooltip>
  );
  return (
    <Box sx={props.styleBig}>
      {props.user ? iconn : iconTooltip}
      <StyledModal
        sx={props.styleSmall}
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={props.openDialog}
        onClose={props.handleClose}
        BackdropComponent={Backdrop}
      >
        <Box
          sx={{
            background: "white",
            border: "1px solid #0C2340",
            borderRadius: "10px",
            p: 2,
            margin: "auto",
          }}
        >
          <div id="unstyled-modal-title">
            <IconButton
              color="primary"
              size="small"
              aria-label="add"
              onClick={props.handleClose}
              style={{ float: "right" }}
            >
              <CancelIcon onClick={props.handleClose} />
            </IconButton>
          </div>
          <Typography sx={props.styleVariant}>{props.children}</Typography>
        </Box>
      </StyledModal>
    </Box>
  );
}
