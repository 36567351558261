const editOpDataLst = (cooperatorId, loanOp, loan, loanDate, loanEditList) =>
  loanEditList.find(
    (item) =>
      Number(item.cooperatorId) === Number(cooperatorId) &&
      item.loanOp === loanOp &&
      item.loan === loan &&
      item.loanDate === loanDate
  );
export default editOpDataLst;

// editOpDataLst("id", "new", "main", "date");
// //
// editOpDataLst("id", "additional", "main", "date");
// //
// editOpDataLst("id", "new", "soft", "date");
// editOpDataLst("id", "new", "other", "date");
// editOpDataLst("id", "new", "food", "date");
// editOpDataLst("id", "new", "building", "date");
// editOpDataLst("id", "new", "welfare", "date");
// editOpDataLst("id", "new", "bhe", "date");
// editOpDataLst("id", "new", "essential", "date");
// //
// editOpDataLst("id", "liquidate", "soft", "date");
// editOpDataLst("id", "liquidate", "other", "date");
// editOpDataLst("id", "liquidate", "food", "date");
// editOpDataLst("id", "liquidate", "building", "date");
// editOpDataLst("id", "liquidate", "welfare", "date");
// editOpDataLst("id", "liquidate", "bhe", "date");
// editOpDataLst("id", "liquidate", "essential", "date");
