import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import LightTooltip from "./light-tooltip";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Zoom from "@mui/material/Zoom";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserAccountDialog(props) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <LightTooltip
        TransitionComponent={Zoom}
        title="Personal Ledger"
        placement="right"
      >
        <IconButton onClick={handleClickOpen}>
          <PostAddIcon />
        </IconButton>
      </LightTooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div style={{ padding: 10 }}>{props.children}</div>
      </Dialog>
    </div>
  );
}
