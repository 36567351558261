import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useFetch from "./use-fetch";

export const PostReportTable = React.forwardRef((props, ref) => {
  const { reports, postDate, startDate, dateRange } = props;
  const { cooperatorName } = useFetch();
  const rSavings = (report) => report.SAVINGS;
  const rShares = (report) => report.SHARES;
  const rFirstBank = (report) => report.FIRST_BANK_LOAN;
  const rMainloan = (report) => report.MAINLOAN;
  const rSubtotal = (report) => report.SUB_TOTAL;
  const rEssentail = (report) => report.ESSENTIAL_COMMODITY;
  const rEducational = (report) => report.EDUCATIONAL_LOAN;
  const rFoodStuff = (report) => report.FOOD_STUFF;
  const rRss = (report) => report.RSS;
  const rWelfare = (report) => report.WELFARE;
  const rEntranceFees = (report) => report.ENTRANCE_FEES;
  const rGrand = (report) => report.GRAND_TOTAL;
  const totalSum = (reportAttr) =>
    reports.length > 0
      ? reports
          .map(reportAttr)
          .reduce((prev, next) => Number(prev) + Number(next))
      : null;
  return (
    <TableContainer
      ref={ref}
      component={Paper}
      sx={{ background: "rgba(229,255,239,1)", pb: 3 }}
    >
      <div
        style={{
          margin: "auto",
          textAlign: "center",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <span style={{ fontWeight: "bold" }}>
          NATIONAL CENTRE FOR TECHNOLOGY MANAGEMENT
        </span>
        <br />
        <span>OBAFEMI AWOLOWO UNIVERSITY</span>
        <br />
        <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>
          GRAND SUMMARY OF PAYROLL FOR THE
        </span>
        {startDate ? (
          <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>
            {" "}
            YEAR {new Date(startDate).getFullYear()}
            {dateRange &&
            new Date(startDate).getFullYear() !==
              new Date(postDate).getFullYear()
              ? "-" + new Date(postDate).getFullYear()
              : ""}
          </span>
        ) : (
          <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>
            {" "}
            MONTH OF{" "}
            {new Date(postDate).toLocaleString("default", {
              month: "long",
            })}{" "}
            {new Date(postDate).getFullYear()}
          </span>
        )}
      </div>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>SN</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>NAMES</TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              SAVINGS
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              SHARES
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              FIRST BANK LOAN
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              MAIN LOAN REPAYMENT
            </TableCell>
            <TableCell align="centre" sx={{ fontWeight: "bold" }}>
              SUB TOTAL
            </TableCell>
            <TableCell align="centre" sx={{ fontWeight: "bold" }}>
              ESSENTIAL COMMODITY
            </TableCell>
            <TableCell align="centre" sx={{ fontWeight: "bold" }}>
              EDUCATIONAL LOAN
            </TableCell>
            <TableCell align="centre" sx={{ fontWeight: "bold" }}>
              FOOD STUFF
            </TableCell>
            <TableCell align="centre" sx={{ fontWeight: "bold" }}>
              RSS
            </TableCell>
            <TableCell align="centre" sx={{ fontWeight: "bold" }}>
              WELFARE
            </TableCell>
            <TableCell align="centre" sx={{ fontWeight: "bold" }}>
              ENTRANCE FEES
            </TableCell>
            <TableCell align="centre" sx={{ fontWeight: "bold" }}>
              GRAND TOTAL
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report, index) => (
            <TableRow
              key={report.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="report">
                {index + 1}
              </TableCell>
              <TableCell component="th" scope="report">
                {cooperatorName(report.id)}
              </TableCell>
              <TableCell align="right">{report.SAVINGS}</TableCell>
              <TableCell align="right">{report.SHARES}</TableCell>
              <TableCell align="right">{report.FIRST_BANK_LOAN}</TableCell>
              <TableCell align="right">{report.MAINLOAN}</TableCell>
              <TableCell
                align="right"
                style={{ background: "rgba(162,231,227,0.2990371148459384)" }}
              >
                {report.SUB_TOTAL}
              </TableCell>
              <TableCell align="right">{report.ESSENTIAL_COMMODITY}</TableCell>
              <TableCell align="right">{report.EDUCATIONAL_LOAN}</TableCell>
              <TableCell align="right">{report.FOOD_STUFF}</TableCell>
              <TableCell align="right">{report.RSS}</TableCell>
              <TableCell align="right">{report.WELFARE}</TableCell>
              <TableCell align="right">{report.ENTRANCE_FEES}</TableCell>
              <TableCell
                align="right"
                style={{ background: "rgba(162,231,227,0.2990371148459384)" }}
              >
                {report.GRAND_TOTAL}
              </TableCell>
            </TableRow>
          ))}

          <TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              background: "rgba(162,231,227,0.4990371148459384)",
            }}
          >
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              #
            </TableCell>
            <TableCell align="left" sx={{ fontWeight: "bold" }}>
              Total
            </TableCell>
            <TableCell align="right">{totalSum(rSavings)}</TableCell>
            <TableCell align="right">{totalSum(rShares)}</TableCell>
            <TableCell align="right">{totalSum(rFirstBank)}</TableCell>
            <TableCell align="right">{totalSum(rMainloan)}</TableCell>
            <TableCell
              align="right"
              style={{ background: "rgba(162,231,227,0.4990371148459384)" }}
            >
              {totalSum(rSubtotal)}
            </TableCell>
            <TableCell align="right">{totalSum(rEssentail)}</TableCell>
            <TableCell align="right">{totalSum(rEducational)}</TableCell>
            <TableCell align="right">{totalSum(rFoodStuff)}</TableCell>
            <TableCell align="right">{totalSum(rRss)}</TableCell>
            <TableCell align="right">{totalSum(rWelfare)}</TableCell>
            <TableCell align="right">{totalSum(rEntranceFees)}</TableCell>
            <TableCell
              align="right"
              style={{ background: "rgba(162,231,227,0.4990371148459384)" }}
            >
              {totalSum(rGrand)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});
