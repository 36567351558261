import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/constants";

const initialState = {
  cooperators: [],
  posts: [],
  comments: [],
  settings: {},
  newMainLoanAutoData: {},
  newMainLoanProcessing: false,
  additionalLoanProcessing: false,
  newOtherLoanProcessing: false,
  newEssentialLoanProcessing: false, //////
  newBheLoanProcessing: false, /////
  newEssentialLoanAutoData: {}, ////
  newBheLoanAutoData: {}, ////
  newOtherLoanAutoData: {},
  newFoodLoanProcessing: false,
  newFoodLoanAutoData: {},
  newWelfareLoanProcessing: false,
  newWelfareLoanAutoData: {},
  newBuildingLoanProcessing: false,
  newBuildingLoanAutoData: {},
  newSoftLoanProcessing: false,
  newSoftLoanAutoData: {},

  payUpMainLoanProcessing: false,
  payUpSoftLoanProcessing: false,
  payUpOtherLoanProcessing: false,
  payUpFoodLoanProcessing: false,
  payUpWelfareLoanProcessing: false,
  payUpBuildingLoanProcessing: false,
  payUpEssentialLoanProcessing: false,
  payUpBheLoanProcessing: false,
  /////////
  payPartMainLoanProcessing: false,
  payPartOtherLoanProcessing: false,
  payPartSoftLoanProcessing: false,
  payPartFoodLoanProcessing: false,
  payPartWelfareLoanProcessing: false,
  payPartBuildingLoanProcessing: false,
  payPartEssentialLoanProcessing: false,
  payPartBheLoanProcessing: false,
  ///////////
  noDedMainLoanProcessing: false,
  noDedOtherLoanProcessing: false,
  noDedSoftLoanProcessing: false,
  noDedFoodLoanProcessing: false,
  noDedWelfareLoanProcessing: false,
  noDedBuildingLoanProcessing: false,
  noDedEssentialLoanProcessing: false,
  noDedBheLoanProcessing: false,
  //////////
  existingMainLoanProcessing: false,
  existingOtherLoanProcessing: false,
  existingSoftLoanProcessing: false,
  existingFoodLoanProcessing: false,
  existingWelfareLoanProcessing: false,
  existingBuildingLoanProcessing: false,
  existingEssentialLoanProcessing: false,
  existingBheLoanProcessing: false,
  //////////
  loanEditList: [],
  postReport: [],
  yearlyPostReport: [],
  status: "idle",
  error: "",
  authenticated: false,
  userId: "",
  signedInUserPosts: [],
  users: [],
  admin: {},
  formValues: {
    id: "",
    postId: "",
    postDate: "",
    postPrevDate: "",
    postParticuars: "",
    totalDeductions: 0,
    sharesDr: 0,
    sharesCr: 0,
    sharesBal: 0,
    savingsCr: 0,
    savingsDr: 0,
    savingsBal: 0,
    rssDr: 0,
    rssCr: 0,
    rssBal: 0,
    specialDepDr: 0,
    specialDepCr: 0,
    specialDepBal: 0,
    totalAsset: 0,
    mainLoanDr: 0,
    mainLoanCr: 0,
    mainLoanBal: 0,
    mainLoanPrevBal: 0,
    mainLoanInt: 0,
    softLoanDr: 0,
    softLoanCr: 0,
    softLoanBal: 0,
    softLoanPrevBal: 0,
    softLoanInt: 0,
    fbDr: 0,
    fbCr: 0,
    fbBal: 0,
    fbInt: 0,
    foodStuffCr: 0,
    foodStuffDr: 0,
    foodStuffBal: 0,
    foodStuffPrevBal: 0,
    foodStuffInt: 0,
    welfareCr: 0,
    welfareDr: 0,
    welfareBal: 0,
    welfarePrevBal: 0,
    welfareInt: 0,
    otherLoanDr: 0,
    otherLoanCr: 0,
    otherLoanBal: 0,
    otherLoanPrevBal: 0,
    otherLoanInt: 0,
    buildingFundDr: 0,
    buildingFundCr: 0,
    buildingFundBal: 0,
    buildingFundPrevBal: 0,
    buildingFundInt: 0,
    essentialCommodityDr: 0,
    essentialCommodityCr: 0,
    essentialCommodityBal: 0,
    essentialCommodityPrevBal: 0,
    essentialCommodityInt: 0,
    bheLoanDr: 0,
    bheLoanCr: 0,
    bheLoanBal: 0,
    bheLoanPrevBal: 0,
    bheLoanInt: 0,
    adminFessChargesDr: 0,
    adminFessChargesCr: 0,
    adminFessChargesBal: 0,
    developmentLevyCr: 0,
    entranceRegFeeCr: 0,
    postedBy: "",
  },
};
// function isPlainObject(obj) {
//   return (
//     typeof obj === "object" && // separate from primitives
//     obj !== null && // is obvious
//     obj.constructor === Object && // separate instances (Array, DOM, ...)
//     Object.prototype.toString.call(obj) === "[object Object]"
//   ); // separate build-in like Math
// }
function sortArray(arr) {
  arr.sort(function (a, b) {
    let x = a.fname.toLowerCase();
    let y = b.fname.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
}
function setObjectEmpty(obj) {
  for (let key in obj) {
    delete obj[key];
  }
}
// /users/:userId
export const newCommentsAsync = createAsyncThunk(
  "comments/newCommentsAsync",
  async (commentData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/comments`, commentData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchCommentsAsync = createAsyncThunk(
  "comments/fetchCommentsAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/comments`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateCommentsAsync = createAsyncThunk(
  "cooperators/updateCommentsAsync",
  async (commentList, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/updateAsViewed`,
        commentList
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteUserAsync = createAsyncThunk(
  "cooperators/deleteUserAsync",
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${API_URL}/users/${userId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteAutoRecord4PayUpAsyc = createAsyncThunk(
  "cooperators/deleteAutoRecord4PayUpAsyc",
  async (delAutoValues4PayUp, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/mainloanmetaDelete`,
        delAutoValues4PayUp
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAutoMainLoanRecordAsyc = createAsyncThunk(
  "cooperators/deleteAutoMainLoanRecord",
  async (delAutoValues, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/mainloanmetaDelete`,
        delAutoValues
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
); //loanEdit/:mainLoanAutoId loanEdit/:cooperatorId

export const signUpAsync = createAsyncThunk(
  "cooperators/signup",
  async ({ id, role, username, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/signup`, {
        id,
        role,
        username,
        password,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const signUpEditAsync = createAsyncThunk(
  "cooperators/signUpEditAsync",
  async ({ userId, role, username, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/users`, {
        userId,
        role,
        username,
        password,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const signinAsync = createAsyncThunk(
  "cooperators/signinAsync",
  async ({ role, username, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/signin`, {
        role,
        username,
        password,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const newPostsAsync = createAsyncThunk(
  "posts/newposts",
  async (postData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/posts`, postData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const editPostsAsync = createAsyncThunk(
  "posts/editPostsAsync",
  async (postData, { getState, rejectWithValue }) => {
    const postId = getState().cics.formValues.postId;
    try {
      const response = await axios.put(`${API_URL}/posts/${postId}`, postData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchPostsAsync = createAsyncThunk(
  "cooperators/fetchPosts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/posts`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchUsersAsync = createAsyncThunk(
  "cooperators/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/users`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//
export const fetchpostReportYearAsync = createAsyncThunk(
  "cooperators/fetchpostReportYearAsync",
  async ({ startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/postReportYear`, {
        startDate,
        endDate,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPostReportAsync = createAsyncThunk(
  "cooperators/fetchPostReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/postReport`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createBulkLoansAsync = createAsyncThunk(
  "cooperators/createBulkLoansAsync",
  async (bulkLoanValues, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_URL}/bulkloans}`,
        bulkLoanValues
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const additionalLoanAsyc = createAsyncThunk(
  "cooperators/additionalLoanAsyc",
  async (additionalData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/loanEdit`, additionalData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const payUpAsyc = createAsyncThunk(
  "cooperators/payUpAsyc",
  async (payUpList, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/loanEdit`, payUpList);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const parPartAsync = createAsyncThunk(
  "cooperators/parPartAsync",
  async (payPartList, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/loanEdit`, payPartList);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const noDedAsync = createAsyncThunk(
  "cooperators/noDedAsync",
  async (noDedList, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/loanEdit`, noDedList);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const editAccountAsync = createAsyncThunk(
  "cooperators/editAccount",
  async ({ userId, role, username, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/users2`, {
        userId,
        role,
        username,
        password,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const newCooperatorsAsync = createAsyncThunk(
  "cooperators/newCooperators",
  async (
    {
      ledger_id,
      fname,
      mname,
      lname,
      department,
      location,
      admission_date,
      cessation_date,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${API_URL}/cooperators`, {
        ledger_id,
        fname,
        mname,
        lname,
        department,
        location,
        admission_date,
        cessation_date,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const fetchLoanEditAsync = createAsyncThunk(
  "cooperators/fetchLoanEditAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/loanEdit`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const newLoanEditAsync = createAsyncThunk(
  "cooperators/newLoanEditAsync",
  async (bulkLoanValues, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/loanEdit`, bulkLoanValues);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateNewMainLoanAutoremainingPeriodAsync = createAsyncThunk(
  "cooperators/updateNewMainLoanAutoremainingPeriod",
  async (bulkAutoRemainingValues, { rejectWithValue }) => {
    // We send the initial data to the API server
    try {
      const response = await axios.put(
        `${API_URL}/mainloanmetaremainingPeriod`,
        bulkAutoRemainingValues
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateLoanAutoRemPeriodPayPartAsync = createAsyncThunk(
  "cooperators/updateLoanAutoRemPeriodPayPartAsync",
  async (updateAutoValues4RemPeriodPayPart, { rejectWithValue }) => {
    // We send the initial data to the API server
    try {
      const response = await axios.put(
        `${API_URL}/mainloanmetaremainingPeriod`,
        updateAutoValues4RemPeriodPayPart
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateLoanAutoRemPeriodNoDedAsync = createAsyncThunk(
  "cooperators/updateLoanAutoRemPeriodNoDedAsync",
  async (updateAutoValues4NoDeduction, { rejectWithValue }) => {
    // We send the initial data to the API server
    try {
      const response = await axios.put(
        `${API_URL}/mainloanmetaremainingPeriod`,
        updateAutoValues4NoDeduction
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchCooperatorsAsync = createAsyncThunk(
  "cooperators/fetchCooperators",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/cooperators`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editCooperatorsAsync = createAsyncThunk(
  "cooperators/editCooperators",
  // The payload creator receives the partial `{id}` object
  async (
    {
      id,
      ledger_id,
      fname,
      mname,
      lname,
      department,
      location,
      admission_date,
      cessation_date,
    },
    { rejectWithValue }
  ) => {
    // We send the initial data to the API server
    try {
      const response = await axios.put(`${API_URL}/cooperators/${id}`, {
        ledger_id,
        fname,
        mname,
        lname,
        department,
        location,
        admission_date,
        cessation_date,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateSettingsAsync = createAsyncThunk(
  "cooperators/updateSettings",
  // The payload creator receives the partial `{id}` object
  async (
    {
      settingsMainLoanInt,
      settingsSoftLoanInt,
      settingsOtherLoanInt,
      settingsBuilgingFundInt,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(`${API_URL}/settings`, {
        settingsMainLoanInt,
        settingsSoftLoanInt,
        settingsOtherLoanInt,
        settingsBuilgingFundInt,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchSettingsAsync = createAsyncThunk(
  "cooperators/fetchSettings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/settings`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteCooperatorsAsync = createAsyncThunk(
  "cooperators/deleteCooperators",
  // The payload creator receives the partial `{id}` object
  async (id, { rejectWithValue }) => {
    // We send the initial data to the API server
    try {
      const response = await axios.delete(`${API_URL}/cooperators/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deletePostAsync = createAsyncThunk(
  "posts/deletePost",
  // The payload creator receives the partial `{id}` object
  async ({ postId }, { rejectWithValue }) => {
    // We send the initial data to the API server
    try {
      const response = await axios.delete(`${API_URL}/posts/${postId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePostsByCooperatorIdAsync = createAsyncThunk(
  "posts/deletePostsByCooperatorIdAsync",
  // The payload creator receives the partial `{id}` object
  async (id, { rejectWithValue }) => {
    // We send the initial data to the API server
    try {
      const response = await axios.delete(`${API_URL}/postsDelCoopertor/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const cooperatorsSlice = createSlice({
  name: "cics",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNewMainLoanAutoData: (state, action) => {
      state.newMainLoanAutoData = action.payload;
    },
    updateNewMainLoanProcessing: (state, action) => {
      state.newMainLoanProcessing = action.payload;
    },
    resetNewMainLoanAutoData: (state, action) => {
      for (let key in state.newMainLoanAutoData) {
        delete state.newMainLoanAutoData[key];
      }
    },
    updateAdditionalLoanProcessing: (state, action) => {
      state.additionalLoanProcessing = action.payload;
    },
    updateExistingLoan: (state, action) => {
      const { isOn, loan } = action.payload;
      if (loan === "main") {
        state.existingMainLoanProcessing = isOn;
      }
      if (loan === "other") {
        state.existingOtherLoanProcessing = isOn;
      }
      if (loan === "soft") {
        state.existingSoftLoanProcessing = isOn;
      }
      if (loan === "food") {
        state.existingFoodLoanProcessing = isOn;
      }
      if (loan === "welfare") {
        state.existingWelfareLoanProcessing = isOn;
      }
      if (loan === "building") {
        state.existingBuildingLoanProcessing = isOn;
      }
      if (loan === "essential") {
        state.existingEssentialLoanProcessing = isOn;
      }
      if (loan === "bhe") {
        state.existingBheLoanProcessing = isOn;
      }
    },
    updateNodeduction: (state, action) => {
      const { isOn, loan } = action.payload;
      if (loan === "main") {
        state.noDedMainLoanProcessing = isOn;
      }
      if (loan === "other") {
        state.noDedOtherLoanProcessing = isOn;
      }
      if (loan === "soft") {
        state.noDedSoftLoanProcessing = isOn;
      }
      if (loan === "food") {
        state.noDedFoodLoanProcessing = isOn;
      }
      if (loan === "welfare") {
        state.noDedWelfareLoanProcessing = isOn;
      }
      if (loan === "building") {
        state.noDedBuildingLoanProcessing = isOn;
      }
      if (loan === "essential") {
        state.noDedEssentialLoanProcessing = isOn;
      }
      if (loan === "bhe") {
        state.noDedBheLoanProcessing = isOn;
      }
    },
    updatePayPart: (state, action) => {
      const { isOn, loan } = action.payload;
      if (loan === "main") {
        state.payPartMainLoanProcessing = isOn;
      }
      if (loan === "other") {
        state.payPartOtherLoanProcessing = isOn;
      }
      if (loan === "soft") {
        state.payPartSoftLoanProcessing = isOn;
      }
      if (loan === "food") {
        state.payPartFoodLoanProcessing = isOn;
      }
      if (loan === "welfare") {
        state.payPartWelfareLoanProcessing = isOn;
      }
      if (loan === "building") {
        state.payPartBuildingLoanProcessing = isOn;
      }
      if (loan === "essential") {
        state.payPartEssentialLoanProcessing = isOn;
      }
      if (loan === "bhe") {
        state.payPartBheLoanProcessing = isOn;
      }
    },
    updatePayUp: (state, action) => {
      const { isOn, loan } = action.payload;
      if (loan === "main") {
        state.payUpMainLoanProcessing = isOn;
      }
      if (loan === "other") {
        state.payUpOtherLoanProcessing = isOn;
      }
      if (loan === "soft") {
        state.payUpSoftLoanProcessing = isOn;
      }
      if (loan === "food") {
        state.payUpFoodLoanProcessing = isOn;
      }
      if (loan === "welfare") {
        state.payUpWelfareLoanProcessing = isOn;
      }
      if (loan === "building") {
        state.payUpBuildingLoanProcessing = isOn;
      }
      if (loan === "essential") {
        state.payUpEssentialLoanProcessing = isOn;
      }
      if (loan === "bhe") {
        state.payUpBheLoanProcessing = isOn;
      }
    },
    setNewLoanAutoData: (state, action) => {
      const { loan } = action.payload;
      if (loan === "soft") {
        state.newSoftLoanAutoData !== undefined &&
          setObjectEmpty(state.newSoftLoanAutoData);
        state.newSoftLoanAutoData = action.payload;
      } else if (loan === "other") {
        state.newOtherLoanAutoData !== undefined &&
          setObjectEmpty(state.newOtherLoanAutoData);
        state.newOtherLoanAutoData = action.payload;
      } else if (loan === "food") {
        state.newFoodLoanAutoData !== undefined &&
          setObjectEmpty(state.newFoodLoanAutoData);
        state.newFoodLoanAutoData = action.payload;
      } else if (loan === "welfare") {
        state.newWelfareLoanAutoData !== undefined &&
          setObjectEmpty(state.newWelfareLoanAutoData);
        state.newWelfareLoanAutoData = action.payload;
      } else if (loan === "building") {
        state.newBuildingLoanAutoData !== undefined &&
          setObjectEmpty(state.newBuildingLoanAutoData);
        state.newBuildingLoanAutoData = action.payload;
      } else if (loan === "main") {
        state.newMainLoanAutoData !== undefined &&
          setObjectEmpty(state.newMainLoanAutoData);
        state.newMainLoanAutoData = action.payload;
      } else if (loan === "essential") {
        state.newEssentialLoanAutoData !== undefined &&
          setObjectEmpty(state.newEssentialLoanAutoData);
        state.newEssentialLoanAutoData = action.payload;
      } else if (loan === "bhe") {
        state.newBheLoanAutoData !== undefined &&
          setObjectEmpty(state.newBheLoanAutoData);
        state.newBheLoanAutoData = action.payload;
      }
    },
    updateNewLoanProcessing: (state, action) => {
      const { isOn, loan } = action.payload;
      if (loan === "soft") {
        state.newSoftLoanProcessing = isOn;
      } else if (loan === "other") {
        state.newOtherLoanProcessing = isOn;
      } else if (loan === "food") {
        state.newFoodLoanProcessing = isOn;
      } else if (loan === "welfare") {
        state.newWelfareLoanProcessing = isOn;
      } else if (loan === "building") {
        state.newBuildingLoanProcessing = isOn;
      } else if (loan === "essential") {
        state.newEssentialLoanProcessing = isOn;
      } else if (loan === "bhe") {
        state.newBheLoanProcessing = isOn;
      }
    },
    resetNewLoanAutoData: (state, action) => {
      if (state.newMainLoanAutoData !== undefined) {
        setObjectEmpty(state.newMainLoanAutoData);
        state.newMainLoanProcessing = false;
        state.payUpMainLoanProcessing = false;
        state.additionalLoanProcessing = false;
        state.noDedMainLoanProcessing = false;
        state.payPartMainLoanProcessing = false;
        state.existingMainLoanProcessing = false;
      }
      if (state.newSoftLoanAutoData !== undefined) {
        setObjectEmpty(state.newSoftLoanAutoData);
        state.newSoftLoanProcessing = false;
        state.payUpSoftLoanProcessing = false;
        state.noDedSoftLoanProcessing = false;
        state.payPartSoftLoanProcessing = false;
        state.existingSoftLoanProcessing = false;
      }
      if (state.newOtherLoanAutoData !== undefined) {
        setObjectEmpty(state.newOtherLoanAutoData);
        state.newOtherLoanProcessing = false;
        state.payUpOtherLoanProcessing = false;
        state.noDedOtherLoanProcessing = false;
        state.payPartOtherLoanProcessing = false;
        state.existingOtherLoanProcessing = false;
      }
      if (state.newFoodLoanAutoData !== undefined) {
        setObjectEmpty(state.newFoodLoanAutoData);
        state.newFoodLoanProcessing = false;
        state.payUpFoodLoanProcessing = false;
        state.noDedFoodLoanProcessing = false;
        state.payPartFoodLoanProcessing = false;
        state.existingFoodLoanProcessing = false;
      }
      if (state.newWelfareLoanAutoData !== undefined) {
        setObjectEmpty(state.newWelfareLoanAutoData);
        state.newWelfareLoanProcessing = false;
        state.payUpWelfareLoanProcessing = false;
        state.noDedWelfareLoanProcessing = false;
        state.payPartWelfareLoanProcessing = false;
        state.existingWelfareLoanProcessing = false;
      }
      if (state.newBuildingLoanAutoData !== undefined) {
        setObjectEmpty(state.newBuildingLoanAutoData);
        state.newBuildingLoanProcessing = false;
        state.payUpBuildingLoanProcessing = false;
        state.noDedBuildingLoanProcessing = false;
        state.payPartBuildingLoanProcessing = false;
        state.existingBuildingLoanProcessing = false;
      }
      if (state.newEssentialLoanAutoData !== undefined) {
        setObjectEmpty(state.newEssentialLoanAutoData);
        state.newEssentialLoanProcessing = false;
        state.payUpEssentialLoanProcessing = false;
        state.noDedEssentialLoanProcessing = false;
        state.payPartEssentialLoanProcessing = false;
        state.existingEssentialLoanProcessing = false;
      }
      if (state.newBheLoanAutoData !== undefined) {
        setObjectEmpty(state.newBheLoanAutoData);
        state.newBheLoanProcessing = false;
        state.payUpBheLoanProcessing = false;
        state.noDedBheLoanProcessing = false;
        state.payPartBheLoanProcessing = false;
        state.existingBheLoanProcessing = false;
      }
    },
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },

    updateFieldValue: (state, action) => {
      const { formName, formValue } = action.payload;
      state.formValues[formName] = formValue;
    },
    updateBal: (state, action) => {
      state.formValues.sharesBal =
        Number(state.formValues.sharesBal) +
        Number(state.formValues.sharesCr) -
        Number(state.formValues.sharesDr);
    },
    setFieldInitialValue: (state, action) => {
      for (let key in state.formValues) {
        if (state.formValues.hasOwnProperty(key)) {
          state.formValues[key] = action.payload[key];
        }
      }
    },
    clearFieldInitialValue: (state, action) => {
      for (let key in state.formValues) {
        if (state.formValues.hasOwnProperty(key)) {
          state.formValues[key] = "";
        }
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: {
    [fetchCooperatorsAsync.pending]: (state) => {
      state.status = "loading";
    },
    [fetchCooperatorsAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.cooperators = action.payload;
    },
    [fetchCooperatorsAsync.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [newCooperatorsAsync.pending]: (state) => {
      state.status = "loading";
    },
    [newCooperatorsAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.cooperators.push(action.payload[0]);
      sortArray(state.cooperators);
      state.users = action.payload[1];
    },
    [deleteCooperatorsAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // state.cooperators = action.payload;
      const dat = state.cooperators;
      for (let i = 0; i < dat.length; i++) {
        if (Number(dat[i].id) === Number(action.payload.id)) {
          dat.splice(i, 1);
        }
      }
      const fUser = state.users;
      for (let i = 0; i < fUser.length; i++) {
        if (Number(fUser[i].id) === Number(action.payload.id)) {
          fUser.splice(i, 1);
        }
      }
    },
    [editCooperatorsAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.cooperators = action.payload;
      // const dat = state.cooperators;
      // for (let i = 0; i < dat.length; i++) {
      //   if (Number(dat[i].id) === Number(action.payload.id)) {
      //     dat.splice(i, 1, action.payload);
      //   }
      // }
    },
    [newPostsAsync.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [newPostsAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.posts = action.payload;
    },
    [editPostsAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.posts = action.payload;
    },
    [editPostsAsync.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchPostsAsync.pending]: (state) => {
      state.status = "loading";
    },
    [fetchPostsAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.posts = action.payload;
    },
    [deletePostAsync.fulfilled]: (state, action) => {
      const dat = state.posts;
      for (let i = 0; i < dat.length; i++) {
        if (Number(dat[i].postId) === Number(action.payload.postId)) {
          dat.splice(i, 1);
        }
      }
    }, //
    [deletePostsByCooperatorIdAsync.fulfilled]: (state, action) => {
      // state.posts = action.payload;
      const dat = state.posts;
      for (let i = 0; i < dat.length; i++) {
        if (Number(dat[i].postId) === Number(action.payload.postId)) {
          dat.splice(i, 1);
        }
      }
     
    },
    [updateSettingsAsync.fulfilled]: (state, action) => {
      state.settings = action.payload;
    },
    [fetchSettingsAsync.pending]: (state) => {
      state.status = "loading";
    },
    [fetchSettingsAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.settings = action.payload;
    },
    [fetchLoanEditAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loanEditList = action.payload;
    },
    [newLoanEditAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loanEditList = action.payload;
    },
    [additionalLoanAsyc.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loanEditList = action.payload;
    },
    [payUpAsyc.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loanEditList = action.payload;
    },
    [parPartAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loanEditList = action.payload;
    },
    [noDedAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loanEditList = action.payload;
    },
    [editAccountAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.users = action.payload;
    },
    [fetchpostReportYearAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.yearlyPostReport = action.payload;
    },
    [fetchpostReportYearAsync.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchPostReportAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.postReport = action.payload;
    },
    [signinAsync.pending]: (state, action) => {
      state.status = "loading";
    },
    [signinAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { id, role, username, password, userId } = action.payload;
      if (role && role !== "user") {
        state.admin = action.payload;
      }
      if (role && role === "user" && username) {
        // state.signedInUserPosts = state.posts.filter(
        //   (post) => Number(post.id) === Number(id)
        // );
        // state.authenticated = true;
        state.userId = id;
      }

      // role === "user" && username
      //   ? (state.signedInUserPosts = state.posts.filter(
      //       (post) => Number(post.id) === Number(id)
      //     ))
      //   : null;

      // if (isPlainObject(action.payload)) {
      //   state.admin = action.payload;
      // } else {
      //   state.signedInUserPosts = action.payload;
      //   state.authenticated = action.payload.length > 0 ? true : false;
      // }
    },
    [signinAsync.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchUsersAsync.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchUsersAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.users = action.payload;
    },
    [signUpAsync.pending]: (state) => {
      state.status = "loading";
    },
    [signUpEditAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.users = action.payload;
    },
    [signUpAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.users = action.payload;
    },
    [signUpAsync.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    //
    [deleteAutoRecord4PayUpAsyc.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loanEditList = action.payload;
    },
    [updateCommentsAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.comments = action.payload;
    },
    [deleteUserAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.users = action.payload;
    },
    [fetchCommentsAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.comments = action.payload;
    },
    [deleteAutoMainLoanRecordAsyc.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loanEditList = action.payload;
    },
    [updateNewMainLoanAutoremainingPeriodAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loanEditList = action.payload;
      // for (let i = 0; i < dat.length; i++) {
      //   for (let j = 0; j < dat2.length; j++) {
      //     if (
      //       Number(dat[i].cooperatorId) === Number(dat2[j].cooperatorId) &&
      //       dat[i].loan === dat2[j].loan
      //     ) {
      //       dat.splice(i, 1, dat2[j]);
      //     }
      //   }
      // }
    },
    [updateLoanAutoRemPeriodPayPartAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loanEditList = action.payload;
    },
    [updateLoanAutoRemPeriodNoDedAsync.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loanEditList = action.payload;
    },
  },
});
//
export const {
  setNewMainLoanAutoData,
  setNewLoanAutoData,
  updateNewLoanProcessing,
  updateNewMainLoanProcessing,
  resetNewMainLoanAutoData,
  resetNewLoanAutoData,
  updatePayUp,
  updateNodeduction,
  updateExistingLoan,
  updatePayPart,
  updateAdditionalLoanProcessing,
  setAuthenticated,
  updateFieldValue,
  setFieldInitialValue,
  clearFieldInitialValue,
  updateBal,
} = cooperatorsSlice.actions;
export const selectStore = (state) => state.cics;
export default cooperatorsSlice.reducer;
