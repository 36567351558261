import * as React from "react";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import useFetch from "./use-fetch";
import { useFeedBack, FeedBack } from "./feedback";
import LinearProgress from "@mui/material/LinearProgress";
import { unwrapResult } from "@reduxjs/toolkit";

export default function CooperatorCore({ id, operaton }) {
  const dispatch = useDispatch();
  const { newCooperatorsAsync, editCooperatorsAsync, cooperators } = useFetch();
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  const cooperator =
    id &&
    cooperators.find((cooperator) => Number(cooperator.id) === Number(id));
  return (
    <React.Fragment>
      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Formik
        enableReinitialize={true}
        initialValues={{
          ledger_id: cooperator !== undefined ? cooperator.ledger_id : "",
          fname: cooperator !== undefined ? cooperator.fname : "",
          mname: cooperator !== undefined ? cooperator.mname : "",
          lname: cooperator !== undefined ? cooperator.lname : "",
          department: cooperator !== undefined ? cooperator.department : "",
          location: cooperator !== undefined ? cooperator.location : "",
          admission_date:
            cooperator !== undefined ? cooperator.admission_date : "",
          cessation_date:
            cooperator !== undefined ? cooperator.cessation_date : "",
        }}
        validationSchema={Yup.object({
          ledger_id: Yup.string().required("This field is required"),
          fname: Yup.string().required("This field is required"),
          mname: Yup.string().required("This field is required"),
          lname: Yup.string().required("This field is required"),
        })}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          setSubmitting(true);
          try {
            const coopData = {
              id,
              ledger_id: values.ledger_id,
              fname: values.fname,
              mname: values.mname,
              lname: values.lname,
              department: values.department,
              location: values.location,
              admission_date: values.admission_date,
              cessation_date: values.cessation_date,
            };
            const operation =
              operaton === "new"
                ? newCooperatorsAsync(coopData)
                : editCooperatorsAsync(coopData);
            const result = await dispatch(operation).then(unwrapResult);
            if (result !== undefined) {
              operaton === "new"
                ? setMsg("Operation was successfully")
                : setMsg("Update was successful");
              setOpenSnack(true);
            }
          } catch (err) {
            setMsg("Operation was NOT unsuccessful");
          } finally {
            setSubmitting(false);
            operaton === "new" && setFieldValue("ledger_id", "");
            operaton === "new" && setFieldValue("fname", "");
            operaton === "new" && setFieldValue("mname", "");
            operaton === "new" && setFieldValue("lname", "");
            operaton === "new" && setFieldValue("department", "");
            operaton === "new" && setFieldValue("location", "");
            operaton === "new" && setFieldValue("admission_date", "");
            operaton === "new" && setFieldValue("cessation_date", "");
            // handleClose();
          }
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            {isSubmitting && <LinearProgress color="secondary" />}
            <Box
              sx={{
                width: "98%",
                margin: "auto",
                marginTop: 5,
              }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} md={6} xl={6} lg={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    multiline
                    name="fname"
                    label="First Name"
                    style={{ marginBottom: 20 }}
                    type="text"
                    size="small"
                    variant="outlined"
                  />{" "}
                  <Field
                    component={TextField}
                    fullWidth
                    multiline
                    name="mname"
                    label="Middle Name"
                    style={{ marginBottom: 20 }}
                    type="text"
                    size="small"
                    variant="outlined"
                  />{" "}
                  <Field
                    component={TextField}
                    fullWidth
                    multiline
                    name="lname"
                    label="Last Name"
                    style={{ marginBottom: 20 }}
                    type="text"
                    size="small"
                    variant="outlined"
                  />{" "}
                </Grid>
                <Grid item xs={12} md={6} xl={6} lg={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    multiline
                    name="ledger_id"
                    label="Ledger Id"
                    style={{ marginBottom: 20 }}
                    type="text"
                    size="small"
                    variant="outlined"
                  />{" "}
                  <Field
                    component={TextField}
                    fullWidth
                    multiline
                    name="department"
                    label="Department"
                    style={{ marginBottom: 20 }}
                    type="text"
                    size="small"
                    variant="outlined"
                  />{" "}
                  <Field
                    component={TextField}
                    fullWidth
                    multiline
                    name="location"
                    label="Location"
                    style={{ marginBottom: 20 }}
                    type="text"
                    size="small"
                    variant="outlined"
                  />{" "}
                </Grid>
                <Grid item xs={12} md={6} xl={6} lg={6}>
                  <Field
                    component={TextField}
                    fullWidth
                    name="admission_date"
                    label="Admission Date"
                    style={{ marginBottom: 20 }}
                    type="date"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />{" "}
                  <Field
                    component={TextField}
                    fullWidth
                    name="cessation_date"
                    label="Cessation Date"
                    style={{ marginBottom: 20 }}
                    type="date"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />{" "}
                </Grid>
              </Grid>
            </Box>
            <div style={{ maxWidth: 300, margin: 10 }}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                style={{
                  margin: 20,
                  background: "#0C2340",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  textTransform: "none",
                }}
              >
                {isSubmitting ? (
                  <span style={{ color: "yellow" }}>... submitting</span>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}
