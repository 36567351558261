import React, { Suspense } from "react";
import Header from "./header";
import Stack from "@mui/material/Stack";
// import StatisticsCard from "./statistics-card";
// import PostHistoryCard from "./post-history-card";
import useFetch from "./use-fetch";
// import PostHistoryNav from "./post-history-nav";
import Card from "./card";
// import YearlyReportRange from "./post-report-yearly-range";
// import YearlyReport from "./post-report-yearly";
import { Navigate, useNavigate } from "react-router-dom";
// import ViewComments from "./view-comments";
import Skeleton from "@mui/material/Skeleton";
const ViewComments = React.lazy(() => import("./view-comments"));
const PostHistoryCard = React.lazy(() => import("./post-history-card"));
const PostHistoryNav = React.lazy(() => import("./post-history-nav"));
const YearlyReportRange = React.lazy(() => import("./post-report-yearly-range"));
const YearlyReport = React.lazy(() => import("./post-report-yearly"));
const StatisticsCard = React.lazy(() => import("./statistics-card"));
export function Skeleton2() {
  return (
    <>
      <Skeleton
        sx={{ height: 190, mb: 5 }}
        animation="wave"
        variant="rectangular"
      />
      <Skeleton
        sx={{ height: 190, mb: 5 }}
        animation="wave"
        variant="rectangular"
      />
    </>
  );
}

export default function AdminDashBoard() {
  const { getPosts, admin } = useFetch();
  React.useEffect(() => {
    getPosts();
  }, []);

  return (
    <>
      <Header user="admin" />

      {admin.username ? (
        <Stack
          direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
          spacing={2}
          alignItems="flex-start"
          justifyContent="center"
          style={{ margin: 3 }}
        >
          {/* <StatisticsCard />
        <PostHistoryCard /> <PostHistoryNav />
          <YearlyReport />
          <PostReportYearly />*/}

          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            style={{ margin: 3 }}
          >
           
            <Card
              bg="linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)"
              title="Cooperator's Comments"
              imgPath="/postImage.jpg"
              component={ <Suspense fallback={<Skeleton2 />}>
                            <ViewComments />
                          </Suspense>}
            />
            <Card
              bg="rgba(0,127,128,0.47942927170868344)"
              title="Personal Ledger"
              imgPath="/sheets-icon.png"
              component={<Suspense fallback={<Skeleton2 />}>
                            <PostHistoryCard dashboard="true" />
                          </Suspense>}
            />
            
            <Card
              bg="rgba(0,127,128,0.47942927170868344)"
              title="Monthly Report"
              imgPath="/report1.png"
              component={<Suspense fallback={<Skeleton2 />}>
                            <PostHistoryNav dashboard="true" />
                          </Suspense>}
            />
            
          </Stack>
          {/* Second Card */}
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            style={{ margin: 3 }}
          >
            <Card
              bg="linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)"
              title="Custom Date Report"
              imgPath="/calendar-icon.png"
              component={<Suspense fallback={<Skeleton2 />}>
                            <YearlyReportRange dashboard="true" />
                          </Suspense>}
            />
                          

            <Card
              bg="rgba(0,127,128,0.47942927170868344)"
              title="Yearly Report"
              imgPath="/report2.png"
              component={<Suspense fallback={<Skeleton2 />}>
                            <YearlyReport dashboard="true" />
                          </Suspense>}
            />
            
            <Card
              bg="linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)"
              title="Quick Statistics"
              imgPath="/chart-icon.png"
              component={<Suspense fallback={<Skeleton2 />}>
                            <StatisticsCard dashboard="true" />
                          </Suspense>}
            />
            
          </Stack>
        </Stack>
      ) : (
        <Navigate to={"/admin"} replace />
      )}
    </>
  );
}
