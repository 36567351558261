import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import EditUserUser from "./editUserUser";
import useFetch from "./use-fetch";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import AdminList from "./AdminList";
import Divider from "@mui/material/Divider";
import CreateAdmin from "./create-admin";
import "./site-style.css";
import useDialog from "./useDialog";

const pages = ["Posts", "Cooperators"];

const Header = ({ user }) => {
  const { authenticated, setAuthenticated, userId, cooperatorName, admin } =
    useFetch();
  const userFirstName = userId && cooperatorName(userId).split(" ")[0];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogOutUser = () => {
    dispatch(setAuthenticated(false));
    window.localStorage.setItem("authenticated", false);
    navigate("/signin", { replace: true });
    setAnchorElUser(null);
  };
  const handleLogOutAdmin = () => {
    dispatch(setAuthenticated(false));
    window.localStorage.setItem("authenticated", false);
    navigate("/admin", { replace: true });
    setAnchorElUser(null);
  };

  return (
    <React.Fragment>
      <Typography
        className="site-name"
        variant="p"
        component="div"
        style={{
          width: "100%",
          margin: "auto",
          fontSize: 20,
          textAlign: "center",
          background: "rgba(0,127,128,0.47942927170868344)",
          paddingTop: 10,
          paddingBottom: 10,
          color: "#005f69",
          fontWeight: "bold",
        }}
      >
        NACETEM (IFE) STAFF COOPERATIVE INVESTMENT AND CREDIT SOCIETY (NSCICS)
      </Typography>
      <AppBar position="static" sx={{ background: "#005f69" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
            >
              {user !== "user" && admin !== undefined ? (
                <NavLink to={"/admin-dashboard"}>
                  <img
                    src="/logo.png"
                    alt="logo"
                    style={{ marginRight: 10, width: "50px", height: "50px" }}
                  />
                </NavLink>
              ) : (
                <img
                  src="/logo.png"
                  alt="logo"
                  style={{ marginRight: 10, width: "50px", height: "50px" }}
                />
              )}
            </Typography>
            {user !== "user" &&
              admin !== undefined &&
              (admin.role === "book keeper" || admin.role === "president") && (
                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <Tooltip title="Menu">
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{}}
                  >
                    {admin.role === "book keeper" &&
                      pages.map((page) => (
                        <MenuItem key={page} onClick={handleCloseNavMenu}>
                          <NavLink
                            to={"/" + page}
                            key={page}
                            onClick={handleCloseNavMenu}
                            style={{
                              color: "black",
                              marginRight: 10,
                              marginLeft: 10,
                            }}
                          >
                            {page}
                          </NavLink>
                        </MenuItem>
                      ))}
                    {user !== "user" &&
                      admin !== undefined &&
                      admin.role === "president" && <AdminList /> && (
                        <AdminList />
                      )}
                  </Menu>
                </Box>
              )}
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              <img src="/logo.png" alt="logo" width="50px" height="50px" />
            </Typography>
            {user !== "user" &&
              admin !== undefined &&
              admin.role === "book keeper" && (
                <Box
                  sx={{
                    color: "primary",
                    flexGrow: 1,
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  {pages.map((page) => (
                    <NavLink
                      to={"/" + page}
                      key={page}
                      onClick={handleCloseNavMenu}
                      style={{
                        color: "#15f4ee",
                        marginRight: 10,
                        marginLeft: 10,
                      }}
                      // sx={{ my: 2, color: "#15f4ee", display: "block" }}
                    >
                      {page}
                    </NavLink>
                  ))}
                </Box>
              )}
            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            ></Box>{" "}
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {user !== "user" &&
                admin !== undefined &&
                admin.role === "president" && <AdminList />}
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ color: "#15f4ee", display: { xs: "none", md: "flex" } }}
            />{" "}
            <span style={{ color: "#fff", marginLeft: 5, marginRight: 5 }}>
              {" "}
              {admin !== undefined && admin.role !== undefined &&(
                <span>
                  <span style={{ color: "#000" }}> Hi, </span> {admin.role}
                </span>
              )}
              {user == "user" && userFirstName !== undefined &&(
                <span>
                  <span style={{ color: "#000" }}> Hi, </span> {userFirstName}
                </span>
              )}
            </span>
            &nbsp;&nbsp;
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt=""
                    src="/acct_icon.png"
                    sx={{ width: 25, height: 25 }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {authenticated && user === "user" && (
                  <MenuItem onClick={handleCloseUserMenu}>
                    {<EditUserUser />}
                  </MenuItem>
                )}
                {admin !== undefined && admin.role && user === "admin" && (
                  <MenuItem onClick={handleCloseUserMenu}>
                    <span>
                      <CreateAdmin
                        editRole={true}
                        newEdit="edit"
                        header="header"
                        userId={admin.userId}
                      />
                    </span>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={
                    user === "user" ? handleLogOutUser : handleLogOutAdmin
                  }
                >
                  <span
                    sx={{
                      textAlign: "center",
                      color: "black",
                      fontSize: 15,
                    }}
                  >
                    <LogoutIcon
                      fontSize="inherit"
                      style={{ marginLeft: 6, marginBottom: "-3px" }}
                    />{" "}
                    &nbsp; Logout
                  </span>
                </MenuItem>
              </Menu>
            </Box>
            {/* ///////// Avatar ends */}
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
};
export default Header;
