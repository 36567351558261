import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import { TextField } from "formik-mui";
import useFetch from "./use-fetch";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircle from "@mui/icons-material/AccountCircle";
import KeyIcon from "@mui/icons-material/Key";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";

export default function SignInAdmin() {
  const [pvalues, setPvalues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChangep = (prop) => (event) => {
    setPvalues({ ...pvalues, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPvalues({
      ...pvalues,
      showPassword: !pvalues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { admin, signinAsync, status, error } = useFetch();
  return (
    <>
      <Box
        sx={{
          maxWidth: 400,
          margin: "auto",
          mt: 2,
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            role: "",
            username: "",
            password: "",
          }}
          validationSchema={Yup.object({
            role: Yup.string().required("*Role is required"),
            username: Yup.string().required("*Username is required"),
            password: Yup.string().required("*Password is required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const result = await dispatch(
              signinAsync({
                role: values.role,
                username: values.username,
                password: values.password,
              })
            ).then(unwrapResult);
            if (result !== undefined && admin !== undefined) {
              window.localStorage.setItem("authenticated", true);
              navigate("/admin-dashboard", { replace: true });
            } else {
              console.log("An Error Occured! ");
            }
            // .then((res) => {

            //
            //   // dispatch(fetchCooperatorsAsync());
            //   // dispatch(setAuthenticated(true));
            //   // return navigate("/useraccount", { replace: true });
            // })
            // .catch((err) => console.log("An Error Occured! "))
            // .finally(setSubmitting(false));
          }}
        >
          {({ submitForm, isSubmitting, values, handleChange }) => (
            <Form>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 5,
                  width: "100%",
                  margin: "auto",
                  background:
                    "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                }}
              >
                {isSubmitting && <LinearProgress color="secondary" />}
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 3 }}
                >
                  {status === "failed" && (
                    <h5
                      style={{
                        color: "red",
                        border: "solid 1px rgba(236, 91, 91, 0.479) ",
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 18,
                        paddingRight: 18,
                        borderRadius: 10,
                      }}
                    >
                      {error.message}
                    </h5>
                  )}
                </Stack>
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <AdminPanelSettingsIcon size="large" />
                  {"  "}
                  <Typography
                    component="div"
                    noWrap
                    style={{ marginTop: "20", marginBottom: "20" }}
                  >
                    Hello, Admin
                  </Typography>
                  <Field
                    style={{
                      background:
                        "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                    }}
                    component={TextField}
                    fullWidth
                    name="role"
                    type="text"
                    size="small"
                    label="Role"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AssignmentIndIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    // input[type="text"]
                    style={{
                      background:
                        "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                    }}
                    component={TextField}
                    fullWidth
                    name="username"
                    type="text"
                    size="small"
                    label="Username"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    style={{
                      background:
                        "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                    }}
                    component={TextField}
                    fullWidth
                    name="password"
                    size="small"
                    label="Password"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type={pvalues.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <KeyIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{
                      margin: 20,
                      background: "#0C2340",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      textTransform: "none",
                    }}
                  >
                    {isSubmitting ? (
                      <span style={{ color: "yellow" }}>... submitting</span>
                    ) : (
                      "Sign Me In"
                    )}
                  </Button>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
