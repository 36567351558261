import React from "react";
import Box from "@mui/material/Box";

export default function Card({ title, imgPath, component, bg }) {
  // rgba(0,127,128,0.47942927170868344)
  //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)"
  return (
    <Box
      sx={{
        background: "rgba(#f1f1f1,0.47942927170868344)",
        "&:hover": {
          background:
            "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
          borderBottom: "solid 1px yellow",
        },
        boxShadow: 3,
        borderRadius: 2,
        p: 2,
        m: 2,
        minWidth: 300,
      }}
    >
      <Box
        sx={{
          color: "#009688",
          "&:hover": {
            color: "15f4ee",
          },
          mb: 3,
          fontSize: 18,
        }}
      >
        {title}
        <img
          src={imgPath}
          style={{
            float: "right",
            width: 60,
            height: 60,
          }}
        />
      </Box>
      <Box sx={{ pt: 1 }}>{component}</Box>
    </Box>
  );
}
