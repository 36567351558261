import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import LightTooltip from "./light-tooltip";
import Zoom from "@mui/material/Zoom";
import { useFeedBack, FeedBack } from "./feedback";
import Stack from "@mui/material/Stack";
import useFetch from "./use-fetch";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CancelIcon from "@mui/icons-material/Cancel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CreateAdmin({ userId, newEdit, header, editRole }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { signUpAsync, signUpEditAsync, users, status, error } = useFetch();
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  const user = users.find((user) => Number(user.userId) === Number(userId));
  return (
    <div>
      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      {newEdit === "edit" && header !== "header" ? (
        <LightTooltip
          TransitionComponent={Zoom}
          title="Update Admin"
          placement="right"
        >
          <IconButton
            onClick={handleClickOpen}
            sx={{ fontSize: 15, color: "#15f4ee" }}
          >
            <ManageAccountsIcon /> &nbsp;
            {header === "header" && "Change Password"}
          </IconButton>
        </LightTooltip>
      ) : newEdit === "edit" ? (
        <IconButton
          onClick={handleClickOpen}
          sx={{ fontSize: 15, color: "#000" }}
        >
          <ManageAccountsIcon /> &nbsp;
          {header === "header" && "Change Password"}
        </IconButton>
      ) : (
        <LightTooltip
          TransitionComponent={Zoom}
          title="Create Admin"
          placement="top"
        >
          <Fab
            color="primary"
            size="small"
            aria-label="add"
            onClick={handleClickOpen}
            style={{ position: "absolute", right: 15, bottom: 15, zIndex: 3 }}
          >
            <AddIcon onClick={handleClickOpen} />
          </Fab>
        </LightTooltip>
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            background: "#ADD8E6",
          }}
        >
          <span
            style={{
              // border: "solid 1px white",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              // background: "rgba(161, 235, 171, 0.479)",
            }}
          >
            {/* <AccountBox style={{ margin: 10, marginBottom: -5 }} /> */}
            <img
              src="/acct_icon.png"
              style={{ height: 20, marginRight: 10, marginBottom: -3 }}
            />
            {newEdit === "edit" ? "Update Admin" : "Create Admin"}
          </span>
          &nbsp;
          <IconButton onClick={handleClose} style={{ float: "right" }}>
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            background:
              "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <Formik
              enableReinitialize={true}
              initialValues={{
                role: newEdit === "edit" && user !== undefined ? user.role : "",
                username:
                  newEdit === "edit" && user !== undefined ? user.username : "",
                password:
                  newEdit === "edit" && user !== undefined ? user.password : "",
              }}
              validationSchema={Yup.object({
                role: Yup.string().required("Role field is required"),
                username: Yup.string().required("Username field is required"),
                password: Yup.string().required("Password field is required"),
              })}
              onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                setSubmitting(true);
                const signUpData = {
                  userId,
                  role: values.role,
                  username: values.username,
                  password: values.password,
                };
                const signUpOperation =
                  newEdit === "edit"
                    ? signUpEditAsync(signUpData)
                    : signUpAsync(signUpData);
                const result = await dispatch(signUpOperation).then(
                  unwrapResult
                );
                if (result !== undefined) {
                  newEdit === "new" && setMsg("Admin Created successfully");
                  newEdit === "edit" && setMsg("Admin Edited successfully");
                  setOpenSnack(true);
                  handleClose();
                } else {
                  setMsg("An error occured");
                  setOpenSnack(true);
                }
                setSubmitting(false);
                setFieldValue("role", "");
                setFieldValue("username", "");
                setFieldValue("password", "");
              }}
            >
              {({ submitForm, isSubmitting, values }) => (
                <Form>
                  {isSubmitting && <LinearProgress color="secondary" />}
                  <Box
                    sx={{
                      width: "100%",
                      marginTop: 2,
                    }}
                  >
                    {status === "failed" && (
                      <h5 style={{ color: "red" }}>
                        {error.alreadyExits &&
                          "Username already exists. Choose another one."}
                      </h5>
                    )}
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Field
                        component={TextField}
                        fullWidth
                        name="role"
                        label="Role"
                        type="text"
                        size="small"
                        disabled={newEdit === "edit" && editRole === true}
                        variant="outlined"
                      />
                      <Field
                        component={TextField}
                        fullWidth
                        name="username"
                        label="Username"
                        type="text"
                        size="small"
                        variant="outlined"
                      />
                      <Field
                        component={TextField}
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        size="small"
                        variant="outlined"
                      />
                    </Stack>
                  </Box>
                  <Stack>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      style={{
                        margin: 20,
                        background: "#0C2340",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                      }}
                    >
                      {newEdit === "edit" ? "Update Admin" : "Create Admin"}
                    </Button>
                    <Button onClick={handleClose}>Cancel</Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
