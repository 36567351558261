const getEditInitialValues = (
  posts,
  editPostId,
  editPostDate,
  dispatch,
  setFieldInitialValue
) => {
  const postDetails = posts.find(
    (post) =>
      Number(post.postId) === Number(editPostId) &&
      new Date(post.postDate).toLocaleDateString("pt-PT") ===
        new Date(editPostDate).toLocaleDateString("pt-PT")
  );
  const editValues = {
    id: postDetails.id,
    postId: postDetails.postId,
    postDate: postDetails.postDate,
    postParticuars: postDetails.postParticuars,
    totalDeductions: postDetails.totalDeductions,
    sharesDr: postDetails.sharesDr,
    sharesCr: postDetails.sharesCr,
    sharesBal: postDetails.sharesBal,
    savingsCr: postDetails.savingsCr,
    savingsDr: postDetails.savingsDr,
    savingsBal: postDetails.savingsBal,
    rssDr: postDetails.rssDr,
    rssCr: postDetails.rssCr,
    rssBal: postDetails.rssBal,
    specialDepDr: postDetails.specialDepDr,
    specialDepCr: postDetails.specialDepCr,
    specialDepBal: postDetails.specialDepBal,
    totalAsset: postDetails.totalAsset,

    mainLoanDr: postDetails.mainLoanDr,
    mainLoanCr: postDetails.mainLoanCr,
    mainLoanBal: postDetails.mainLoanBal,
    mainLoanInt: postDetails.mainLoanInt,

    softLoanDr: postDetails.softLoanDr,
    softLoanCr: postDetails.softLoanCr,
    softLoanBal: postDetails.softLoanBal,
    softLoanInt: postDetails.softLoanInt,

    fbDr: postDetails.fbDr,
    fbCr: postDetails.fbCr,
    fbBal: postDetails.fbBal,
    fbInt: postDetails.fbInt,

    foodStuffCr: postDetails.foodStuffCr,
    foodStuffDr: postDetails.foodStuffDr,
    foodStuffBal: postDetails.foodStuffBal,
    foodStuffInt: postDetails.foodStuffInt,

    welfareCr: postDetails.welfareCr,
    welfareDr: postDetails.welfareDr,
    welfareBal: postDetails.welfareBal,
    welfareInt: postDetails.welfareInt,

    otherLoanDr: postDetails.otherLoanDr,
    otherLoanCr: postDetails.otherLoanCr,
    otherLoanBal: postDetails.otherLoanBal,
    otherLoanInt: postDetails.otherLoanInt,

    buildingFundDr: postDetails.buildingFundDr,
    buildingFundCr: postDetails.buildingFundCr,
    buildingFundBal: postDetails.buildingFundBal,
    buildingFundInt: postDetails.buildingFundInt,

    essentialCommodityDr: postDetails.essentialCommodityDr,
    essentialCommodityCr: postDetails.essentialCommodityCr,
    essentialCommodityBal: postDetails.essentialCommodityBal,
    essentialCommodityInt: postDetails.essentialCommodityInt,

    bheLoanDr: postDetails.bheLoanDr,
    bheLoanCr: postDetails.bheLoanCr,
    bheLoanBal: postDetails.bheLoanBal,
    bheLoanInt: postDetails.bheLoanInt,

    adminFessChargesDr: postDetails.adminFessChargesDr,
    adminFessChargesCr: postDetails.adminFessChargesCr,
    adminFessChargesBal: postDetails.adminFessChargesBal,
    developmentLevyCr: postDetails.developmentLevyCr,
    entranceRegFeeCr: postDetails.entranceRegFeeCr,
    postedBy: postDetails.postedBy,
  };
  dispatch(setFieldInitialValue(editValues));
};
export default getEditInitialValues;
