import React, { Fragment } from "react";
import Header from "./header";
import CooperatorsList from "./cooperators-list";
import CoorperatorNew from "./cooperator-new";
import Box from "@mui/material/Box";
import useFetch from "./use-fetch";
import LinearProgress from "@mui/material/LinearProgress";

export default function Cooperators() {
  const { cooperators, status, error } = useFetch();
  let content;
  if (status === "loading") {
    content = (
      <div>
        <LinearProgress />
        Loading...
      </div>
    );
  } else if (status === "succeeded") {
    content = <CooperatorsList cooperators={cooperators} />;
  } else if (status === "failed") {
    content = <h5>Sorry! Something Went Wrong.{error}</h5>;
  }
  return (
    <Fragment>
      <Header user="admin" />
      <Box sx={{ margin: 5 }}>
        <Box
          sx={{
            position: "fixed",
            right: 15,
            bottom: 20,
            zIndex: 200,
            display: { xs: "flex", md: "none" },
          }}
        >
          <CoorperatorNew />
        </Box>
        <Box sx={{ mb: 2, display: { xs: "none", md: "flex", lg: "flex" } }}>
          <CoorperatorNew />
          
        </Box>
        {content}
      </Box>
    </Fragment>
  );
}
