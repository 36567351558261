import * as React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import useFetch from "./use-fetch";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PostReportDialog, { usePostReportDialog } from "./post-report-dialog";
import ReportIcon from "@mui/icons-material/Report";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { purple } from "@mui/material/colors";

export default function PostHistoryNav({ dashboard }) {
  const { OpenReportDialog, setOpenReportDialog, handleCloseReportDialog } =
    usePostReportDialog();
  const { posts } = useFetch();
  const monthYear = posts.map(
    (post) =>
      new Date(post.postDate).toLocaleString("default", { month: "long" }) +
      " " +
      new Date(post.postDate).getFullYear()
  );
  const monthYearSet2Arr = [...new Set(monthYear)];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedValue, setSelectedValue] = React.useState();
  return (
    <div>
      <PostReportDialog
        postDate={selectedValue}
        OpenReportDialog={OpenReportDialog}
        handleCloseReportDialog={handleCloseReportDialog}
      />
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={1}
      >
        <div>
          <Button
            sx={{ textTransform: "none" }}
            variant="outlined"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            startIcon={<ReportIcon />}
            endIcon={<KeyboardArrowDownIcon />}
            color="secondary"
          >
            {dashboard ? "Choose Date" : "Monthly Report"}
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {monthYearSet2Arr.map((monthYear) => (
              <MenuItem
                onClick={() => {
                  setSelectedValue(monthYear);
                  setOpenReportDialog(true);
                  return handleClose();
                }}
              >
                {monthYear}
              </MenuItem>
            ))}
          </Menu>
        </div>

        {/* <Item>Item 2</Item>
        <Item>Item 3</Item> */}
        {/* {new Date(post.postDate).toLocaleString("default", {
                  month: "long",
                })}{" "}
                {new Date(post.postDate).getFullYear()}{" "}
                {"(" +
                  new Date(post.postDate).toLocaleDateString("pt-PT") +
                  ")"} */}
      </Stack>
    </div>
  );
}
