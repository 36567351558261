import * as React from "react";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LinearProgress from "@mui/material/LinearProgress";
import { useFeedBack, FeedBack } from "./feedback";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";
import useFetch from "./use-fetch";

export default function UserComents({ userId, handleClose }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { newCommentsAsync } = useFetch();
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  return (
    <div>
      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Formik
        enableReinitialize={true}
        initialValues={{
          comment: "",
        }}
        validationSchema={Yup.object({
          comment: Yup.string().required(
            "You've not entered any comment. Please do so before you submit."
          ),
        })}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          setSubmitting(true);
          const commentData = {
            userId,
            comment: values.comment,
          };
          await dispatch(newCommentsAsync(commentData))
            .then(unwrapResult)
            .then((res) => {
              setMsg("Thanks for the FeedBack. Comment submitted successful");
              setOpenSnack(true);
              setTimeout(() => handleClose(), 6000);
            })
            .catch((error) => {
              setMsg("An error occured");
              setOpenSnack(true);
            });
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            {isSubmitting && <LinearProgress color="secondary" />}

            <Stack
              sx={{ m: 2 }}
              direction="column "
              alignItems="center"
              justifyContent="center"
            >
              <h3>Comment / Feed Back</h3>
              <Field
                component={TextField}
                fullWidth
                name="comment"
                type="text"
                multiline
                rows={5}
                cols={5}
                placeholder="Please enter your comment here"
                margin="dense"
                size="small"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                style={{
                  margin: 20,
                  background: "#0C2340",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  textTransform: "none",
                }}
              >
                {isSubmitting ? (
                  <span style={{ color: "yellow" }}>... sumbitting</span>
                ) : (
                  "Submit"
                )}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
}
