import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import UserFilteryByDate from "./user-filter-by-date";
import useFetch from "./use-fetch";
import Header from "./header";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import ExportToCsv from "./export-report";
import RefreshIcon from "@mui/icons-material/Refresh";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import UserCommentsDialog from "./user-comments-dialog";

export default function UserAccount({ coopId }) {
  const {
    authenticated,
    posts,
    userId,
    cooperatorName,
    admin,
    isObjectNotEmpty,
  } = useFetch();
  const [dateFilter, setDateFiler] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlleRefresh = () => {
    setDateFiler(null);
  };
  const navigate = useNavigate();
  React.useEffect(() => {
    !isObjectNotEmpty(admin) &&
      !authenticated &&
      navigate("/signin", { replace: true });
  });
  const cooperatorId = coopId ? coopId : userId;
  const signedInUserPosts = posts.filter(
    (post) => Number(post.id) === Number(cooperatorId)
  );
  const filteredPost = !dateFilter
    ? signedInUserPosts
    : signedInUserPosts.filter(
        (post) =>
          new Date(post.postDate).toLocaleDateString("pt-PT") ===
          new Date(dateFilter).toLocaleDateString("pt-PT")
      );
  return (
    <div style={{ margin: "auto" }}>
      {!isObjectNotEmpty(admin) && <Header user="user" />}
      {signedInUserPosts.length <= 0 ? (
        <h3> You currently have no personal legder records </h3>
      ) : (
        <Box>
          <Paper sx={{ width: "100%" }}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
              justify="center"
              alignItems="center"
              sx={{
                textAlign: "center",
                margin: "auto",
                background:
                  "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
              }}
            >
              <Tooltip title="Filter list by date">
                <IconButton onClick={handleClick}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reset the list">
                <IconButton onClick={handlleRefresh}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Print this record">
              <IconButton>
                <PrintPage />
              </IconButton>
            </Tooltip> */}
              <Tooltip title="Download as excel">
                <ExportToCsv
                  csvData={filteredPost.map((item) => ({
                    ...item,
                    id: cooperatorName(item.id),
                  }))}
                  fileName={"cics report " + new Date()}
                />
              </Tooltip>
              {!isObjectNotEmpty(admin) && <UserCommentsDialog />}
            </Stack>
          </Paper>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {signedInUserPosts.map((post) => (
              <MenuItem
                onClick={() => {
                  setDateFiler(post.postDate);
                  return handleClose();
                }}
              >
                {new Date(post.postDate).toLocaleString("default", {
                  month: "long",
                })}{" "}
                {new Date(post.postDate).getFullYear()}
              </MenuItem>
            ))}
          </Menu>

          <UserFilteryByDate
            signedInUserPosts={filteredPost}
            dateFilter={dateFilter}
          />
        </Box>
      )}
    </div>
  );
}
