import React from "react";
import Carousel from "react-material-ui-carousel";
import "./site-style.css";

function Item(props) {
  return (
    <div className={props.item.className}>
      <div
        style={{
          padding: 20,
          position: "relative",
          width: "100%",
          height: "100%",
          color: "white",
          background:
            "linear-gradient(135deg, rgba(0,127,128,0.5) 7%," +
            "rgba(118,148,216,0.13) 35%, rgba(162,231,227,0.5) 60%," +
            "rgba(229,255,239,0.5) 93%),rgba(0,0,0,0.5)",
          // background: "rgba(0,0,0,0.47942927170868344)",
          fontFamily: "'Gill Sans Extabold' sans-serif",
        }}
      >
        <h1>{props.item.name}</h1>
        <p>{props.item.description}</p>
      </div>
    </div>
  );
}

export default function CarouselComp(props) {
  var items = [
    {
      name: "Dynamically Enterprsing",
      description: "inInvesting in ...",
      className: "carousel1",
    },
    {
      name: "Investing in stomarch infrastructure",
      description: "Ebi kii wonu koro mii woo ...",
      className: "carousel2",
    },
    {
      name: "Loanning for Empowerment",
      description: "Loanning ...",
      className: "carousel3",
    },
    {
      name: "Brilliant Financial Record and Reporting",
      description: "Innovating in modern technology  ...",
      className: "carousel4",
    },
  ];

  return (
    <Carousel interval={8000}>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}
