import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import DeleteOperation from "./delete-operation";
import CreateAdmin from "./create-admin";
import Skeleton from "@mui/material/Skeleton";
import useFetch from "./use-fetch";
import { useFeedBack, FeedBack } from "./feedback";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CancelIcon from "@mui/icons-material/Cancel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AdminList() {
  const { status, users } = useFetch();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  // userId, username, password, id, role, admin
  return (
    <React.Fragment>
      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <IconButton
        onClick={handleClickOpen}
        sx={{ fontSize: 15, color: "#15f4ee" }}
      >
        <ManageAccountsIcon fontSize="inherit" /> &nbsp;Manage Admins
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{ padding: 20 }}
      >
        <DialogTitle
          style={{
            background: "#ADD8E6",
          }}
        >
          <span
            style={{
              // border: "solid 1px white",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              // background: "rgba(161, 235, 171, 0.479)",
            }}
          >
            {/* <AccountBox style={{ margin: 10, marginBottom: -5 }} /> */}
            <img src="/acct_icon.png" style={{ height: 17, marginRight: 5 }} />
            Manage Admins
          </span>
          &nbsp;
          <IconButton onClick={handleClose} style={{ float: "right" }}>
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            background:
              "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <CreateAdmin
              newEdit="new"
              editRole={true}
              style={{ zIndex: 920 }}
            />

            <List sx={{ width: "100%", maxWidth: 370 }}>
              {/* arrs.map((arr)=>{arr.role !=="user"}) */}
              {users
                .filter((user) => user.role !== "user")
                .map(({ userId, username, password, id, role, admin }) => (
                  <React.Fragment>
                    <ListItem
                      key={userId}
                      alignItems="flex-start"
                      secondaryAction={role !== "president" &&
                        <React.Fragment>
                          <CreateAdmin
                            editRole={false}
                            userId={userId}
                            newEdit="edit"
                            edge="end"
                          />
                          <DeleteOperation
                            userId={userId}
                            operation="admin"
                            edge="end"
                          />
                        </React.Fragment>
                      }
                    >
                      <ListItemText
                        primary={
                          status === "loading" ? (
                            <Skeleton
                              sx={{ height: 190 }}
                              animation="wave"
                              variant="rectangular"
                            />
                          ) : (
                            <React.Fragment>
                              <div>
                                {" "}
                                Username:{" "}
                                <span
                                  style={{
                                    color: "rgb(66, 64, 64)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {username}
                                </span>
                              </div>{" "}
                              <div>
                                Password:{" "}
                                <span
                                  style={{
                                    color: "rgb(66, 64, 64)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {password}
                                </span>
                              </div>{" "}
                              <div>
                                Role:{" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {role}
                                </span>
                              </div>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  textTransform: "uppercase",
                                }}
                              >
                                {admin}
                              </span>
                            </React.Fragment>
                          )
                        }
                      />
                    </ListItem>{" "}
                    <p></p>
                    <Divider variant="inset" component="li" /> <p></p>
                  </React.Fragment>
                ))}
            </List>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
