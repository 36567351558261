import React from "react";
import UserComents from "./user-comments";
import Dialog from "./dialog";
import useFetch from "./use-fetch";
import useDialog from "./useDialog";

export default function UserCommentsDialog() {
  const { userId } = useFetch();
  const [openDialog, handleOpen, handleClose] = useDialog();
  const icon = (
    <small style={{ color: "blue", fontWeight: "bold" }}>
      &nbsp;&nbsp;Comments
    </small>
  );
  return (
    <React.Fragment>
      <Dialog
        icon={icon}
        user={true}
        styleVariant={{ width: 500 }}
        openDialog={openDialog}
        handleOpen={handleOpen}
        handleClose={handleClose}
      >
        <UserComents userId={userId} handleClose={handleClose} />
      </Dialog>
    </React.Fragment>
  );
}
