import * as React from "react";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import useFetch from "./use-fetch";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-mui";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";

export default function SignIn() {
  const [pvalues, setPvalues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChangep = (prop) => (event) => {
    setPvalues({ ...pvalues, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPvalues({
      ...pvalues,
      showPassword: !pvalues.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signinAsync, authenticated, setAuthenticated, status, error } =
    useFetch();

  React.useEffect(() => {
    authenticated && navigate("/useraccount", { replace: true });
    authenticated &&
      window.localStorage.setItem("authenticated", authenticated);
  }, [authenticated]);
  return (
    <React.Fragment>
      <Stack
        direction="row"
        spacing={6}
        alignItems="center"
        justifyContent="space-evenly"
        sx={{ m: 1 }}
      >
        <img
          src="/logo.png"
          alt="logo"
          style={{ marginRight: 10, width: "70px", height: "70px" }}
        />
        <Typography
          sx={{
            maxWidth: "400px",
            // mt: 2,
            textAlign: "center",
            fontFamily: "'Gill Sans Extabold' sans-serif",
            fontWeight: "bold",
            fontSize: 20,
            display: { xs: "none", md: "flex" },
          }}
        >
          NACETEM (IFE) STAFF COOPERATIVE INVESTMENT AND CREDIT SOCIETY (NSCICS)
        </Typography>
        <Typography
          sx={{
            maxWidth: "400px",
            // mt: 2,
            textAlign: "center",
            fontFamily: "'Gill Sans Extabold' sans-serif",
            fontWeight: "bold",
            fontSize: 20,
            display: { xs: "flex", sm: "flex", md: "none" },
          }}
        >
          NSCICS
        </Typography>
        <Link
          to="/"
          variant="contained"
          style={{
            textDecoration: "none",
            border: "solid 2px green",
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 15,
            paddingRight: 15,
            borderRadius: 5,
            color: "green",
          }}
        >
          Home
        </Link>
      </Stack>
      <Box
        sx={{
          maxWidth: 300,
          margin: "auto",
          marginTop: 5,
          borderRadius: 5,
          boxShadow: "10px 10px 20px 20px",
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            role: "user",
            username: "",
            password: "",
          }}
          validationSchema={Yup.object({
            username: Yup.string().required("*Username is required"),
            password: Yup.string().required("*Password is required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
              const result = await dispatch(
                signinAsync({
                  role: "user",
                  username: values.username,
                  password: values.password,
                })
              ).then(unwrapResult);
              // const { id, role, username, password, userId }
              // .then(navigate("/useraccount", { replace: true }));
              result !== null && dispatch(setAuthenticated(true));
            } catch (error) {
              console.log(error.message);
            }
            setSubmitting(false);
          }}
        >
          {({ submitForm, isSubmitting, values, handleChange }) => (
            <Form>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 5,
                  width: "100%",
                  background: "rgba(229,255,239)",
                  margin: "auto",
                  // background:
                  //   "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                }}
              >
                {" "}
                {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 3 }}
                >
                  {status === "failed" && (
                    <h5
                      style={{
                        color: "red",
                        border: "solid 1px rgba(236, 91, 91, 0.479) ",
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 18,
                        paddingRight: 18,
                        borderRadius: 10,
                      }}
                    >
                      {error.message}
                    </h5>
                  )}
                </Stack>
                <Stack
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Typography
                    component="div"
                    noWrap
                    style={{ marginTop: "20", marginBottom: "20" }}
                  >
                    Access Your NACETEM CICS Account
                  </Typography>
                  <Field
                    component={TextField}
                    fullWidth
                    name="username"
                    type="text"
                    size="small"
                    label="Username"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    name="password"
                    size="small"
                    label="Password"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type={pvalues.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ margin: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    style={{
                      margin: 20,
                      background: "#0C2340",
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      textTransform: "none",
                    }}
                  >
                    {isSubmitting ? (
                      <span style={{ color: "yellow" }}>... submitting</span>
                    ) : (
                      "Sign Me In"
                    )}
                  </Button>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </React.Fragment>
  );
}
