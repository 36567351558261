import React from "react";
import Posts from "./features/posting/posts";
import Cooperators from "./features/posting/cooperators";
import Home from "./features/posting/home";
import SignIn from "./features/posting/signin";
import UserAccount from "./features/posting/user-account";
import SignInAdmin from "./features/posting/sign-in-admin";
import PostEdit from "./features/posting/post-edit";
import EditUserAdmin from "./features/posting/editUserAdmin";
import CoorperatorEdit from "./features/posting/cooperator-edit";
import AdminDashBoard from "./features/posting/admin-dashboard";
// import ErrorPage from "./features/posting/error-page";
// import RootLayout from "./features/posting/root-layout";
import ProtectedRoute from "./features/posting/protected-route";
import "./App.css";
// import {
//   createBrowserRouter,
//   RouterProvider,
//   createRoutesFromElements,
//   Route,
// } from "react-router-dom";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cooperators" element={<Cooperators />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/useraccount" element={<UserAccount />} />
        <Route path="/admin" element={<SignInAdmin />} />
        <Route path="/admin-dashboard" element={<AdminDashBoard />} />
        <Route path="/posts" element={<Posts />} />
        <Route
          path="/post-edit/:editPostId/:editPostDate"
          element={<PostEdit />}
        />
        <Route path="/user-edit/:id" element={<EditUserAdmin />} />
        <Route path="/cooperator-edit/:id" element={<CoorperatorEdit />} />

        {/* <Route
    path="/admin-dashboard"
    element={
      <ProtectedRoute redirectPath={"/admin"}>
        <AdminDashBoard />
      </ProtectedRoute>
    }
  /> */}
      </Routes>
    </Router>
  );
}
export default App;
