import React, { Suspense } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
// import ImageTooltip from "./image-tooltip";
import ImagePopover from "./image-popover";
import Skeleton from "@mui/material/Skeleton";
import useFetch from "./use-fetch";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import UserAccountDialog from "./user-account-dialog";
const UserLink = React.lazy(() => import("./user-link"));
const DeleteOperation = React.lazy(() => import("./delete-operation"));
const CooperatorLink = React.lazy(() => import("./link-coorperator-edit"));
const UserAccount = React.lazy(() => import("./user-account"));

function CopUiList({
  id,
  ledger_id,
  fname,
  mname,
  lname,
  department,
  location,
}) {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
        borderBottom: "solid 1px yellow",
        p: 3,
        m: 2,
        borderRadius: 7,
      }}
    >
      <ListItem
        key={id}
        alignItems="flex-start"
        secondaryAction={
          <Suspense fallback={<div>...</div>}>
            <div style={{ margingLeft: 80, marginTop: 12 }}>
              <CooperatorLink id={id} newEdit="edit" edge="end" />
              <DeleteOperation id={id} operation="cooperator" edge="end" />
              <UserLink id={id} edge="end" />
              <UserAccountDialog>
                <UserAccount
                  coopId={id}
                  style={{ marginBottom: 5 }}
                  edge="end"
                />
              </UserAccountDialog>
            </div>
          </Suspense>
        }
      >
        <ListItemAvatar>
          <ImagePopover
            hoverOn={<Avatar alt="X" src={`/${ledger_id}.jpg`} />}
            showUp={
              <img
                src={`/${ledger_id}.jpg`}
                alt=""
                style={{
                  borderRadius: 45,
                  width: 150,
                  height: 150,
                }}
              />
            }
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <span
                style={{
                  color: "rgb(66, 64, 64)",
                  fontWeight: "bold",
                }}
              >
                {fname}
              </span>{" "}
              <span
                style={{
                  color: "rgb(66, 64, 64)",
                  fontWeight: "bold",
                }}
              >
                {mname}
              </span>{" "}
              <span
                style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {lname}
              </span>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Ledger Id: {ledger_id} —
              </Typography>
              {" " + department + " Department, " + location}
            </React.Fragment>
          }
        />
      </ListItem>{" "}
      <p></p>
    </Box>
  );
}

export default function CooperatorsList({ cooperators }) {
  const [searchVal, setSearchVal] = React.useState([]);
  const [searchRes, setsearchRes] = React.useState([]);
  // PREVENTS RERENDER FLICKERING AS USER TYPES IN SEARCH
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  // useEffect - ONLY RERENDERS WHEN searchVal IS CHANGED
  // //////////////////////////////////////////////////
  // React.useEffect(() => {
  //   let currentsearchVal = true;
  //   const loadSearch = async () => {
  //     if (!searchVal) return setsearchRes([]);
  //     await sleep(350);
  //     //Split the search text into word tokens
  //     const lstSearch = searchVal && searchVal.trim().split(" ");
  //     //Search or filter the list of tokens for each token
  //     if (currentsearchVal) {
  //       const lst = [];
  //       for (const token of lstSearch) {
  //         lst.push(
  //           cooperators.filter(
  //             (item) =>
  //               new RegExp(token, "i").exec(item.fname) ||
  //               new RegExp(token, "i").exec(item.mname) ||
  //               new RegExp(token, "i").exec(item.lname) ||
  //               new RegExp(token, "i").exec(item.department)||
  //               new RegExp(token, "i").exec(item.location)
  //           )
  //         );
  //       }
  //       //Flaten the list
  //       const lst2 = lst.flat()
  //       //Uniques records / list
  //       const uniqLst = [...new Set(lst2.map((o) => JSON.stringify(o)))].map((s) =>
  //         JSON.parse(s)
  //       );
  //       //Update the search results
  //       setsearchRes(uniqLst);
  //     }
  //   };
  //   // Make the loadSearch() function call as searchVal changes. 
  //   // Note searchVal is place as a useEffect dependant
  //   loadSearch();
  //   return () => {
  //     currentsearchVal = false;
  //   };
  // }, [searchVal]);
  // //Executed as user types in the search input form
  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   return setSearchVal(e.target.value);
  // };
  // /////////////////////////////////////////////////
  
  const searchList = searchRes !== undefined && searchRes.length > 0
  && searchRes.map(({ id, ledger_id, fname, mname, lname, department, location }) => (
    <CopUiList
      id={id}
      ledger_id={ledger_id}
      fname={fname}
      mname={mname}
      lname={lname}
      department={department}
      location={location}
    />
  ))
  const fullList =
    cooperators !== undefined &&
    cooperators.map(
      ({ id, ledger_id, fname, mname, lname, department, location }) => (
        <CopUiList
          id={id}
          ledger_id={ledger_id}
          fname={fname}
          mname={mname}
          lname={lname}
          department={department}
          location={location}
        />
      )
    );
  return (
    <>
      {/* <TextField
        id="standard-basic"
        label="Search cooperator by name"
        variant="standard"
        multiline
        fullWidth
        margin="dense"
        sx={{ maxWidth: 350, mb: 4 }}
        onChange={handleSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment OnClick={handleSearch} position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      /> */}
      <br/>
      <span style={{fontWeight:"bolder"}}>Total Number of cooperators: {cooperators.length}</span>
      <List
        sx={{
          width: "100%",
          maxWidth: 370,
          // background: "rgba(162,231,227,0.4990371148459384)",
        }}
      >
        {/* {searchRes !== undefined && searchRes.length > 0 ? searchList : fullList} */}
        {fullList}
      </List>
    </>
  );
}
