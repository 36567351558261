import React from "react";
import EditUser from "./editUser";
import useFetch from "./use-fetch";
import Header from "./header";
export default function EditUserAdmin() {
  const { userId } = useFetch();

  return (
    <React.Fragment>
      <Header user="admin" />
      <EditUser caller="cooperator" />
    </React.Fragment>
  );
}
