import * as React from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import LightTooltip from "./light-tooltip";
import Zoom from "@mui/material/Zoom";
import { useFeedBack, FeedBack } from "./feedback";
import useFetch from "./use-fetch";
import { unwrapResult } from "@reduxjs/toolkit";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 400,
  background:
    "linear-gradient(135deg, rgba(0,127,128,0.87942927170868344) 7%, rgba(118,148,216,0.8962359943977591) 35%, rgba(162,231,227,0.8990371148459384) 60%, rgba(229,255,239,1) 93%)",
  border: "1px solid #0C2340",
  borderRadius: "10px",
  p: 2,
  px: 4,
  pb: 3,
};
// operation="cooperator"
export default function DeleteOperation({ id, postId, userId, operation }) {
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  const {
    posts,
    deletePostsByCooperatorIdAsync,
    deleteCooperatorsAsync,
    deletePostAsync,
    deleteUserAsync,
  } = useFetch();
  const hasPosts = posts.find((post) => Number(post.id) === Number(id));
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const handleDelete = () => {
    try {
      const result =
        operation === "cooperator" &&
        dispatch(deleteCooperatorsAsync(id))
          .then(unwrapResult)
          .then((res) => {
            if (res !== undefined && hasPosts !== undefined) {
              dispatch(deletePostsByCooperatorIdAsync(id));
            }
          });
      operation === "post" && dispatch(deletePostAsync({ postId }));
      operation === "admin" && dispatch(deleteUserAsync({ userId }));
      if (result !== undefined) {
        setMsg("Delete operation was successful");
      }
    } catch (error) {
      setMsg("Delete operation NOT unsuccessful");
    } finally {
      setOpenSnack(true);
      handleClose();
    }
  };
  return (
    <div>
      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <LightTooltip TransitionComponent={Zoom} title="Delete" placement="right">
        <IconButton
          aria-label="delete"
          size="small"
          color="secondary"
          onClick={handleOpen}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </LightTooltip>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <p id="unstyled-modal-title">
            <DeleteIcon style={{ marginBottom: "-5px" }} fontSize="small" />
            {operation === "cooperator" && "Delete Cooperator"}
            {operation === "post" && "Delete Cooperator"}
            {operation === "admin" && "Delete Admin"}
          </p>
          <h4 id="unstyled-modal-description" style={{ color: "maroon" }}>
            {hasPosts !== undefined
              ? "The cooperator has associated posts. The associated posts will also be deleted. "
              : ""}
            <span style={{ display: "block" }}>
              Are you sure you want to perform this operation?
            </span>
          </h4>
          <div style={{ maxWidth: 300, margin: 10 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDelete}
              style={{
                // fontWeight: "bolder",
                color: "white",
              }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              style={{
                margin: 20,
                background: "#0C2340",
                paddingLeft: "40px",
                paddingRight: "40px",
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </StyledModal>
    </div>
  );
}
