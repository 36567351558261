import * as React from "react";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import { green } from "@mui/material/colors";
import GridOnIcon from "@mui/icons-material/GridOn";
import Avatar from "@mui/material/Avatar";
export default function ExportToCsv({ csvData, fileName }) {
  return (
    <>
      <IconButton
        variant="outlined"
        title="Export to Excel"
        color="success"
        sx={{ textTransform: "none" }}
      >
        <CSVLink
          data={csvData}
          filename={fileName}
          style={{ color: green[500] }}
        >
          <Avatar
            style={{
              color: green[500],
              backgroundColor: "rgb(154, 214, 154)",
              width: 30,
              height: 30,
            }}
          >
            <DownloadIcon />
          </Avatar>
        </CSVLink>
      </IconButton>
    </>
  );
}
