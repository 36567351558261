import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useFetch from "./use-fetch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PostInner from "./post-inner";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import PostHistory from "./post-history";
import PostHistoryNav from "./post-history-nav"; //
import PostReportYearly from "./post-report-yearly-range";
import Divider from "@mui/material/Divider";
import PostReportDialog, { usePostReportDialog } from "./post-report-dialog";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        margin: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function YearlyReport({ dashboard }) {
  const dispatch = useDispatch();
  const { fetchpostReportYearAsync, posts, status, error } = useFetch();

  const [anchorDate, setAnchorDate] = React.useState(null);
  const openDate = Boolean(anchorDate);
  const handleDate = (event) => {
    setAnchorDate(event.currentTarget);
  };
  const handleCloseDate = () => {
    setAnchorDate(null);
  };
  const [selectedDate, setSelectedDate] = React.useState(null);
  const { OpenReportDialog, setOpenReportDialog, handleCloseReportDialog } =
    usePostReportDialog();
  const monthYear = posts.map((post) => new Date(post.postDate).getFullYear());
  const monthYearSet2Arr = [...new Set(monthYear)];
  return (
    <Stack
      direction="column"
      // spacing={{ xs: 1, sm: 2 }}
      // alignItems="center"
      // justifyContent="center"
    >
      <PostReportDialog
        startDate={`${selectedDate}-01-01`}
        postDate={`${selectedDate}-12-01`}
        dateRange={false}
        OpenReportDialog={OpenReportDialog}
        handleCloseReportDialog={handleCloseReportDialog}
      />
      <Box sx={{ display: "flex" }}>
        <Button
          id="basic-button"
          aria-controls={openDate ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openDate ? "true" : undefined}
          variant="outlined"
          color="secondary"
          disableElevation
          onClick={handleDate}
          startIcon={<ReportGmailerrorredIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          style={{
            textTransform: "none",
            marginBottom: 0,
          }}
        >
          {dashboard ? "Choose Date" : "Yearly Report"}
        </Button>
      </Box>
      <StyledMenu
        id="basic-menu"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorDate}
        open={openDate}
        onClose={handleCloseDate}
      >
        {monthYearSet2Arr.map((year) => (
          <MenuItem
            onClick={() => {
              setSelectedDate(year);
              dispatch(
                fetchpostReportYearAsync({
                  startDate: `${year}-01-01`,
                  endDate: `${year}-12-01`,
                })
              );
              setOpenReportDialog(true);
              return handleCloseDate();
            }}
          >
            {year}
          </MenuItem>
        ))}
        {status === "failed" && (
          <h6
            style={{
              color: "rgba(240, 57, 57, 0.479)",
              fontWeight: "bold",
              margin: 0,
              padding: 5,
            }}
          >
            Something Went Wrong. {error}
          </h6>
        )}
      </StyledMenu>
    </Stack>
  );
}
