import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useFetch from "./use-fetch";
import Header from "./header";
import getEditInitialValues from "./get-edit-initialvalues";
import editOpDataLst from "./edit-op-data";
import PostInner from "./post-inner";

export default function PostEdit() {
  const {
    clearFieldInitialValue,
    resetNewLoanAutoData,
    loanEditList,
    formValues,
    posts,
    setFieldInitialValue,
  } = useFetch();
  let params = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(resetNewLoanAutoData());
    dispatch(clearFieldInitialValue());
    getEditInitialValues(
      posts,
      params.editPostId,
      params.editPostDate,
      dispatch,
      setFieldInitialValue
    );
    console.log(
      "editOpDataLst: ",
      editOpDataLst(
        formValues.id,
        "new",
        "main",
        formValues.postDate,
        loanEditList
      )
    );
    //(cooperatorId, loanOp, loan, loanDate)
  }, []);

  return (
    <React.Fragment>
      <Header user="admin" />
      <div style={{ marginTop: 30 }}>
        <h3 style={{ maxWidth: 200, margin: "auto" }}>:: Edit Page</h3>
        <p></p>
        <PostInner postOp="edit" />
      </div>
    </React.Fragment>
  );
}
