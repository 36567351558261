import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import useFetch from "./use-fetch";
import { unwrapResult } from "@reduxjs/toolkit";
import PostReportDialog, { usePostReportDialog } from "./post-report-dialog";

export default function YearlyReportRange({ dashboard }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const dispatch = useDispatch();
  const { fetchpostReportYearAsync } = useFetch();
  const { OpenReportDialog, setOpenReportDialog, handleCloseReportDialog } =
    usePostReportDialog();
  const [varEndDate, setVarEndDate] = React.useState(null);
  const [varStartDate, setVarStartDate] = React.useState(null);

  return (
    <div>
      <PostReportDialog
        postDate={varEndDate}
        startDate={varStartDate}
        dateRange={true}
        OpenReportDialog={OpenReportDialog}
        handleCloseReportDialog={handleCloseReportDialog}
      />
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        startIcon={<DateRangeIcon />}
        color="secondary"
        sx={{ textTransform: "none" }}
      >
        {dashboard ? "Choose Date" : "Custom Date Report"}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              startDate: "",
              endDate: "",
            }}
            validationSchema={Yup.object({
              startDate: Yup.string().required("This field is required"),
              endDate: Yup.string().required("This field is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              dispatch(
                fetchpostReportYearAsync({
                  startDate: values.startDate,
                  endDate: values.endDate,
                })
              )
                .then(unwrapResult)
                .then(() => {
                  setSubmitting(false);
                  handleClose();
                  setOpenReportDialog(true);
                });
            }}
          >
            {({ submitForm, isSubmitting, handleChange }) => (
              <Form>
                {isSubmitting && <LinearProgress color="secondary" />}

                <Stack
                  direction="column"
                  spacing={{ xs: 1, sm: 2 }}
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    p: 4,
                    background:
                      "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
                  }}
                >
                  <Field
                    component={TextField}
                    fullWidth
                    name="startDate"
                    label="Start Date"
                    margin="dense"
                    type="date"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setVarStartDate(e.target.value);
                    }} //
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    name="endDate"
                    label="End Date"
                    margin="dense"
                    type="date"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      setVarEndDate(e.target.value);
                    }}
                  />
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                      sx={{
                        background: "#0C2340",
                        textTransform: "none",
                      }}
                    >
                      Go
                    </Button>
                  </div>
                </Stack>
              </Form>
            )}
          </Formik>
        </div>
      </Popover>
    </div>
  );
}
