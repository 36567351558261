import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useFetch from "./use-fetch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import PostHistory from "./post-history";
import PostHistoryNav from "./post-history-nav";
import YearlyReportRange from "./post-report-yearly-range";
import YearlyReport from "./post-report-yearly";
import Dialog from "./dialog";
import useDialog from "./useDialog";
import ExportToCsv from "./export-report";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        margin: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function FilterPostByNameMenu() {
  const [openDialog, handleOpen, handleClose] = useDialog();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const { cooperatorName, cooperators, posts, status, error } = useFetch();
  const monthYear = posts.map(
    (post) =>
      new Date(post.postDate).toLocaleString("default", { month: "long" }) +
      " " +
      new Date(post.postDate).getFullYear()
  );
  const monthYearSet2Arr = [...new Set(monthYear)];
  const [postId, setPostId] = React.useState(null);
  const handleMenuItemClick = (id) => {
    setPostId(id);
    setSelectedDate(null);
    return handleCloseMenu();
  };
  const [anchorDate, setAnchorDate] = React.useState(null);
  const openDate = Boolean(anchorDate);
  const handleDate = (event) => {
    setAnchorDate(event.currentTarget);
  };
  const handleCloseDate = () => {
    setAnchorDate(null);
  };
  const [selectedDate, setSelectedDate] = React.useState(null);

  return (
    <Stack
      spacing={2}
      // divider={<Divider orientation="horizontal" flexItem />}
      alignItems="center"
      justifyContent="center"
    >
      {status === "failed" && (
        <h5
          style={{
            color: "red",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 25,
            paddingRight: 25,
            background: "rgba(240, 57, 57, 0.479)",
          }}
        >
          Something Went Wrong ... {error}
        </h5>
      )}
      {status === "loading" && (
        <h5
          style={{
            color: "rgba(3, 56, 10)",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 30,
            paddingRight: 30,
            background: "rgba(161, 235, 171, 0.479)",
          }}
        >
          ...loading
        </h5>
      )}

      <Stack
        direction="row"
        spacing={{ xs: 0, md: 2 }}
        sx={{
          mt: 1,
          display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Box sx={{ display: "flex" }}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="outlined"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            style={{
              margin: "auto",
              textTransform: "none",
              marginBottom: 0,
            }}
          >
            Filter By Name
          </Button>{" "}
        </Box>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
        >
          {cooperators.map(({ id, fname, mname, lname }) => (
            <MenuItem
              key={id}
              onClick={() => handleMenuItemClick(id)}
              disableRipple
              sx={{ color: "rgba(0,127,128)" }}
            >
              {fname + " " + mname + " " + lname}
            </MenuItem>
          ))}
          {status === "failed" && (
            <h6
              style={{
                color: "rgba(240, 57, 57, 0.479)",
                fontWeight: "bold",
                margin: 0,
                padding: 5,
              }}
            >
              Something Went Wrong. {error}
            </h6>
          )}
        </StyledMenu>
        <Box sx={{ display: "flex" }}>
          <Button
            id="basic-button"
            aria-controls={openDate ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openDate ? "true" : undefined}
            variant="outlined"
            disableElevation
            onClick={handleDate}
            endIcon={<KeyboardArrowDownIcon />}
            style={{
              margin: "auto",
              textTransform: "none",
              marginBottom: 0,
            }}
          >
            Filter By Date
          </Button>
        </Box>
        <StyledMenu
          id="basic-menu"
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorEl={anchorDate}
          open={openDate}
          onClose={handleCloseDate}
        >
          {monthYearSet2Arr.map((monthYear) => (
            <MenuItem
              onClick={() => {
                setSelectedDate(monthYear);
                // setPostId(null);
                return handleCloseDate();
              }}
            >
              {monthYear}
            </MenuItem>
          ))}
          {status === "failed" && (
            <h6
              style={{
                color: "rgba(240, 57, 57, 0.479)",
                fontWeight: "bold",
                margin: 0,
                padding: 5,
              }}
            >
              Something Went Wrong. {error}
            </h6>
          )}
        </StyledMenu>
        <Tooltip title="Reset the list">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setPostId(null);
              setSelectedDate(null);
            }}
          >
            <RefreshIcon />
          </Button>
        </Tooltip>
        {/* Report By Date */}
        <PostHistoryNav />
        <YearlyReport />
        <YearlyReportRange />
        <ExportToCsv
          csvData={posts.map((item) => ({
            ...item,
            name: cooperatorName(item.id),
          }))}
          fileName={"Personal Ledger - " + new Date().toLocaleString()}
        /> 
      </Stack>
      <Box sx={{ display: { xs: "flex", sm: "flex", md: "none", lg: "none" } }}>
        <Dialog
          icon={<MenuIcon />}
          styleBig={{
            display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
          }}
          styleSmall={{
            display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
          }}
          styleVariant={{ width: 500 }}
          openDialog={openDialog}
          handleOpen={handleOpen}
          handleClose={handleClose}
        >
          <Stack
            direction="column"
            spacing={2}
            sx={{
              mt: 1,
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ display: "flex" }}>
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="outlined"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                style={{
                  margin: "auto",
                  textTransform: "none",
                  marginBottom: 0,
                }}
              >
                Filter By Name
              </Button>{" "}
            </Box>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
            >
              {cooperators.map(({ id, fname, mname, lname }) => (
                <MenuItem
                  key={id}
                  onClick={() => handleMenuItemClick(id)}
                  disableRipple
                  sx={{ color: "rgba(0,127,128)" }}
                >
                  {fname + " " + mname + " " + lname}
                </MenuItem>
              ))}
              {status === "failed" && (
                <h6
                  style={{
                    color: "rgba(240, 57, 57, 0.479)",
                    fontWeight: "bold",
                    margin: 0,
                    padding: 5,
                  }}
                >
                  Something Went Wrong. {error}
                </h6>
              )}
            </StyledMenu>
            <Box sx={{ display: "flex" }}>
              <Button
                id="basic-button"
                aria-controls={openDate ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDate ? "true" : undefined}
                variant="outlined"
                disableElevation
                onClick={handleDate}
                endIcon={<KeyboardArrowDownIcon />}
                style={{
                  margin: "auto",
                  textTransform: "none",
                  marginBottom: 0,
                }}
              >
                Filter By Date
              </Button>
            </Box>
            <StyledMenu
              id="basic-menu"
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorEl={anchorDate}
              open={openDate}
              onClose={handleCloseDate}
            >
              {monthYearSet2Arr.map((monthYear) => (
                <MenuItem
                  onClick={() => {
                    setSelectedDate(monthYear);
                    // setPostId(null);
                    return handleCloseDate();
                  }}
                >
                  {monthYear}
                </MenuItem>
              ))}
              {status === "failed" && (
                <h6
                  style={{
                    color: "rgba(240, 57, 57, 0.479)",
                    fontWeight: "bold",
                    margin: 0,
                    padding: 5,
                  }}
                >
                  Something Went Wrong. {error}
                </h6>
              )}
            </StyledMenu>
            <Tooltip title="Reset the list">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setPostId(null);
                  setSelectedDate(null);
                }}
              >
                <RefreshIcon />
              </Button>
            </Tooltip>
            {/* Report By Date */}
            <PostHistoryNav />
            <YearlyReport />
            <YearlyReportRange />
            <ExportToCsv
              csvData={posts.map((item) => ({
                ...item,
                name: cooperatorName(item.id),
              }))}
              fileName={"Personal Ledger - " + new Date().toLocaleString()}
            />
          </Stack>
        </Dialog>
      </Box>
      <PostHistory id={postId} postDate={selectedDate} />
    </Stack>
  );
}
