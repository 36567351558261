import React, { Suspense } from "react";
import useFetch from "./use-fetch";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import CircularProgress from "@mui/material/CircularProgress";

const PostColView = React.lazy(() => import("./post-col-view"));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "postDate",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "particulars",
    numeric: false,
    disablePadding: false,
    label: "Particulars",
  },
  {
    id: "totalDeductions",
    numeric: false,
    disablePadding: false,
    label: "Total Deductions",
  },
  {
    id: "sharesDr",
    numeric: false,
    disablePadding: false,
    label: "Shares",
  },
  {
    id: "savingsDr",
    numeric: false,
    disablePadding: false,
    label: "Savings",
  },
  {
    id: "rssDr",
    numeric: false,
    disablePadding: false,
    label: "RSS",
  },
  {
    id: "specialDepDr",
    numeric: false,
    disablePadding: false,
    label: "Special Dep",
  },
  {
    id: "totalAsset",
    numeric: false,
    disablePadding: false,
    label: "Total Asset",
  },
  {
    id: "mainLoanDr",
    numeric: false,
    disablePadding: false,
    label: "Main Loan",
  },
  {
    id: "softLoanDr",
    numeric: false,
    disablePadding: false,
    label: "Bank Loan",
  },
  {
    id: "fbDr",
    numeric: false,
    disablePadding: false,
    label: "First Bank Loan",
  },
  {
    id: "foodStuffDr",
    numeric: false,
    disablePadding: false,
    label: "Food Stuff",
  },
  {
    id: "welfareDr",
    numeric: false,
    disablePadding: false,
    label: "Welfare",
  },
  {
    id: "otherLoanDr",
    numeric: false,
    disablePadding: false,
    label: "Education Loan",
  },
  {
    id: "buildingFundDr",
    numeric: false,
    disablePadding: false,
    label: "Building Fund",
  },
  {
    id: "essentialCommodityDr",
    numeric: false,
    disablePadding: false,
    label: "Essential Commodity",
  },
  {
    id: "bheLoanDr",
    numeric: false,
    disablePadding: false,
    label: "Building / HouseHold Equipment Loan",
  },
  {
    id: "adminFessChargesDr",
    numeric: false,
    disablePadding: false,
    label: "Admin Fess Charges",
  },

  {
    id: "developmentLevyCr",
    numeric: false,
    disablePadding: false,
    label: "Development Levy",
  },
  {
    id: "entranceRegFeeCr",
    numeric: false,
    disablePadding: false,
    label: "Entrance Reg Fee",
  },
  // {
  //   id: "postedBy",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Posted By",
  // },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
          {/* <SettingsIcon /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ fontWeight: "bold" }}> {headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function UserFilteryByDate({ signedInUserPosts }) {
  const { cooperatorName } = useFetch();

  // let rows;
  // if (status === "loading") {
  //   rows = (
  //     <div>
  //       {/* <LinearProgress /> */}
  //       Loading...
  //       <CircularProgress color="success" />
  //     </div>
  //   );
  // } else if (status === "succeeded") {

  // }

  const rows = signedInUserPosts;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <div style={{ margin: "auto" }}>
      <Box sx={{ width: "100%" }}>
        {/* <ExportToCsv csvData={report} fileName="Monthly Report" /> */}
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        // hover
                        // onClick={(event) => handleClick(event, row.name)}
                        // role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        // selected={isItemSelected}
                      >
                        <TableCell>
                          {/* <DeleteOperation
                          postId={row.postId}
                          operation="post"
                          edge="end"
                        /> */}
                         <Suspense fallback={<CircularProgress />}>
                            <PostColView
                              postId={row.postId}
                            />
                          </Suspense>
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {cooperatorName(row.id)}
                          {/* {row.id} */}
                        </TableCell>
                        <TableCell align="left">
                          {new Date(row.postDate).toLocaleDateString("pt-PT")}
                        </TableCell>
                        <TableCell align="left">{row.postParticuars}</TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            backgroundColor:
                              "rgba(162,231,227,0.2990371148459384)",
                          }}
                        >
                          {row.totalDeductions}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              Dr: {row.sharesDr}
                            </TableCell>
                            <TableCell align="left">
                              Cr: {row.sharesCr}
                            </TableCell>
                            <TableCell align="left">
                              Bal: {row.sharesBal}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              Dr: {row.savingsDr}
                            </TableCell>
                            <TableCell align="left">
                              Cr: {row.savingsCr}
                            </TableCell>
                            <TableCell align="left">
                              Bal: {row.savingsBal}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">Dr: {row.rssDr}</TableCell>
                            <TableCell align="left">Cr: {row.rssCr}</TableCell>
                            <TableCell align="left">
                              Bal: {row.rssBal}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              Dr: {row.specialDepDr}
                            </TableCell>
                            <TableCell align="left">
                              Cr: {row.specialDepCr}
                            </TableCell>
                            <TableCell align="left">
                              Bal: {row.specialDepBal}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            backgroundColor:
                              "rgba(162,231,227,0.2990371148459384)",
                          }}
                        >
                          {row.totalAsset}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              Dr: {row.mainLoanDr}
                            </TableCell>
                            <TableCell align="left">
                              Cr: {row.mainLoanCr}
                            </TableCell>
                            <TableCell align="left">
                              Bal: {row.mainLoanBal}
                            </TableCell>
                            <TableCell align="left">
                              Int: {row.mainLoanBal}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              Dr: {row.softLoanDr}
                            </TableCell>
                            <TableCell align="left">
                              Cr: {row.softLoanCr}
                            </TableCell>
                            <TableCell align="left">
                              Bal: {row.softLoanBal}
                            </TableCell>
                            <TableCell align="left">
                              Int: {row.softLoanInt}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">Dr: {row.fbDr}</TableCell>
                            <TableCell align="left">Cr: {row.fbCr}</TableCell>
                            <TableCell align="left">Bal: {row.fbBal}</TableCell>
                            <TableCell align="left">Int: {row.fbInt}</TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              Dr: {row.foodStuffDr}
                            </TableCell>
                            <TableCell align="left">
                              Cr: {row.foodStuffCr}
                            </TableCell>
                            <TableCell align="left">
                              Bal: {row.foodStuffBal}
                            </TableCell>
                            <TableCell align="left">
                              Int: {row.foodStuffInt}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              Dr: {row.welfareDr}
                            </TableCell>
                            <TableCell align="left">
                              Cr: {row.welfareCr}
                            </TableCell>
                            <TableCell align="left">
                              Bal: {row.welfareBal}
                            </TableCell>
                            <TableCell align="left">
                              Int: {row.welfareInt}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              Dr: {row.otherLoanDr}
                            </TableCell>
                            <TableCell align="left">
                              Cr: {row.otherLoanCr}
                            </TableCell>
                            <TableCell align="left">
                              Bal: {row.otherLoanBal}
                            </TableCell>
                            <TableCell align="left">
                              Int: {row.otherLoanInt}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              Dr: {row.buildingFundDr}
                            </TableCell>
                            <TableCell align="left">
                              Cr: {row.buildingFundCr}
                            </TableCell>
                            <TableCell align="left">
                              Bal: {row.buildingFundBal}
                            </TableCell>
                            <TableCell align="left">
                              Int: {row.buildingFundInt}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              Dr: {row.essentialCommodityDr}
                            </TableCell>
                            <TableCell align="left">
                              Cr: {row.essentialCommodityCr}
                            </TableCell>
                            <TableCell align="left">
                              Bal: {row.essentialCommodityBal}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              Dr: {row.bheLoanDr}
                            </TableCell>
                            <TableCell align="left">
                              Cr: {row.bheLoanCr}
                            </TableCell>
                            <TableCell align="left">
                              Bal: {row.bheLoanBal}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {/* inner table row begins */}
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.name}
                            selected={isItemSelected}
                          >
                            <TableCell align="left">
                              Dr: {row.adminFessChargesDr}
                            </TableCell>
                            <TableCell align="left">
                              Cr: {row.adminFessChargesCr}
                            </TableCell>
                            <TableCell align="left">
                              Bal: {row.adminFessChargesBal}
                            </TableCell>
                          </TableRow>

                          {/* inner table row ends */}
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          {row.developmentLevyCr}
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          {row.entranceRegFeeCr}
                        </TableCell>
                        {/* <TableCell align="left">{row.postedBy}</TableCell> */}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </div>
  );
}
