import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import useFetch from "./use-fetch";
import { PostReportTable } from "./post-report-table";
import ExportToCsv from "./export-report";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import IconButton from "@mui/material/IconButton";
import { blue } from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function usePostReportDialog() {
  const [OpenReportDialog, setOpenReportDialog] = React.useState(false);
  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
  };
  return { OpenReportDialog, setOpenReportDialog, handleCloseReportDialog };
}

export default function PostReportDialog({
  postDate,
  startDate,
  dateRange,
  OpenReportDialog,
  handleCloseReportDialog,
}) {
  const { cooperatorName, report, postReport, yearlyPostReport } = useFetch();
  React.useEffect(() => {
    report();
  }, []);
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const filterReport = startDate
    ? yearlyPostReport
    : postReport.filter(
        (post) =>
          new Date(post.postDate).toLocaleString("default", { month: "long" }) +
            " " +
            new Date(post.postDate).getFullYear() ===
          postDate
      );

  return (
    <div>
      <Dialog
        fullScreen
        open={OpenReportDialog}
        onClose={handleCloseReportDialog}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            background:
              "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseReportDialog}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 5, flex: 1 }} component="div">
              REPORT FOR THE{" "}
              {startDate
                ? " YEAR " +
                  new Date(startDate).getFullYear() +
                  (dateRange &&
                  new Date(startDate).getFullYear() !==
                    new Date(postDate).getFullYear()
                    ? "-" + new Date(postDate).getFullYear()
                    : "")
                : "MONTH OF " +
                  new Date(postDate).toLocaleString("default", {
                    month: "long",
                  }) +
                  " " +
                  new Date(postDate).getFullYear()}
            </Typography>
            <Tooltip title="Print Page">
              <IconButton onClick={handlePrint}>
                <PrintIcon style={{ color: blue[500], marginTop: -5 }} />
              </IconButton>
            </Tooltip>
            <ExportToCsv
              csvData={filterReport.map((item) => ({
                ...item,
                NAME: cooperatorName(item.id),
              }))}
              fileName={
                "CICS REPORT - " +
                (startDate
                  ? " YEAR " +
                    new Date(startDate).getFullYear() +
                    (dateRange &&
                    new Date(startDate).getFullYear() !==
                      new Date(postDate).getFullYear()
                      ? "-" + new Date(postDate).getFullYear()
                      : "")
                  : "MONTH OF " +
                    new Date(postDate).toLocaleString("default", {
                      month: "long",
                    }) +
                    " " +
                    new Date(postDate).getFullYear())
              }
            />

            <Button
              autoFocus
              color="secondary"
              onClick={handleCloseReportDialog}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <PostReportTable
          ref={componentRef}
          reports={filterReport}
          postDate={postDate}
          startDate={startDate}
          dateRange={dateRange}
        />
      </Dialog>
    </div>
  );
}
