import * as React from "react";
import Button from "@mui/material/Button";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LinearProgress from "@mui/material/LinearProgress";
import { useFeedBack, FeedBack } from "./feedback";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";
import useFetch from "./use-fetch";

export default function EditUser({ id, caller }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { users, editAccountAsync, admin } = useFetch();
  const { openSnack, setOpenSnack, msg, setMsg, handleCloseSnackbar } =
    useFeedBack();
  const edtUserId =
    caller == "header" ? id : caller == "cooperator" ? params.id : null;
  const user = users.find((user) => Number(user.id) === Number(edtUserId));
  return (
    <div>
      <FeedBack
        openSnack={openSnack}
        msg={msg}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Formik
        enableReinitialize={true}
        initialValues={{
          username: user !== undefined ? user.username : "",
          password: user !== undefined ? user.password : "",
        }}
        validationSchema={Yup.object({
          username: Yup.string().required("This field is required"),
          password: Yup.string().required("This field is required"),
        })}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          setSubmitting(true);
          await dispatch(
            editAccountAsync({
              userId: user.userId,
              role: "user",
              username: values.username,
              password: values.password,
            })
          )
            .then(unwrapResult)
            .then((res) => {
              setMsg("Update was successful");
              setOpenSnack(true);
            })
            .catch((error) => {
              setMsg("An error occured");
              setOpenSnack(true);
            });
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            {isSubmitting && <LinearProgress color="secondary" />}

            <Stack
              sx={{ m: 2 }}
              direction="column "
              alignItems="center"
              justifyContent="center"
            >
              <h3>:: Edit Account</h3>
              <Field
                component={TextField}
                fullWidth
                name="username"
                label="Username"
                type="text"
                margin="dense"
                size="small"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Field
                component={TextField}
                fullWidth
                name="password"
                label="Password"
                type="text"
                margin="dense"
                size="small"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                style={{
                  margin: 20,
                  background: "#0C2340",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  textTransform: "none",
                }}
              >
                {isSubmitting ? (
                  <span style={{ color: "yellow" }}>... sumbitting</span>
                ) : (
                  "Update Account"
                )}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
}
