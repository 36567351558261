import React, { Suspense } from "react";
import useFetch from "./use-fetch";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
import NumberFormat from "react-number-format";


const PostColView = React.lazy(() => import("./post-col-view"));
const DeleteOperation = React.lazy(() => import("./delete-operation"));
const PostLink = React.lazy(() => import("./post-link"));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background:
      "linear-gradient(135deg, rgba(0,127,128,0.4) 7%, rgba(118,148,216,0.4) 35%, rgba(162,231,227,0.4) 60%, rgba(229,255,239,1) 93%)",
    color: theme.palette.common.blue,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: "bold",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 5000,
    numeric: false,
    disablePadding: true,
    label: "Edit",
  },
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "postDate",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "particulars",
    numeric: false,
    disablePadding: false,
    label: "Particulars",
  },
  {
    id: "totalDeductions",
    numeric: false,
    disablePadding: false,
    label: "Total Deductions",
  },
  {
    id: "sharesDr",
    numeric: false,
    disablePadding: false,
    label: "Shares(₦)",
  },
  {
    id: "savingsDr",
    numeric: false,
    disablePadding: false,
    label: "Savings(₦)",
  },
  {
    id: "rssDr",
    numeric: false,
    disablePadding: false,
    label: "RSS(₦)",
  },
  {
    id: "specialDepDr",
    numeric: false,
    disablePadding: false,
    label: "Special Dep(₦)",
  },
  {
    id: "totalAsset",
    numeric: false,
    disablePadding: false,
    label: "Total Asset(₦)",
  },
  {
    id: "mainLoanDr",
    numeric: false,
    disablePadding: false,
    label: "Main Loan(₦)",
  },
  {
    id: "softLoanDr",
    numeric: false,
    disablePadding: false,
    label: "First Bank Loan(₦)",
  },
  // {
  //   id: "fbDr",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "First Bank Loan",
  // },
  {
    id: "foodStuffDr",
    numeric: false,
    disablePadding: false,
    label: "Food Stuff(₦)",
  },
  {
    id: "welfareDr",
    numeric: false,
    disablePadding: false,
    label: "Welfare(₦)",
  },
  {
    id: "otherLoanDr",
    numeric: false,
    disablePadding: false,
    label: "Other Loan(₦)",
  },
  {
    id: "buildingFundDr",
    numeric: false,
    disablePadding: false,
    label: "Building Fund(₦)",
  },
  {
    id: "essentialCommodityDr",
    numeric: false,
    disablePadding: false,
    label: "Essential Commodity(₦)",
  },
  {
    id: "bheLoanDr",
    numeric: false,
    disablePadding: false,
    label: "Building / HouseHold Equipment Loan(₦)",
  },
  {
    id: "adminFessChargesDr",
    numeric: false,
    disablePadding: false,
    label: "Admin Fess Charges(₦)",
  },

  {
    id: "developmentLevyCr",
    numeric: false,
    disablePadding: false,
    label: "Development Levy(₦)",
  },
  {
    id: "entranceRegFeeCr",
    numeric: false,
    disablePadding: false,
    label: "Entrance Reg Fee(₦)",
  },
  {
    id: "postedBy",
    numeric: false,
    disablePadding: false,
    label: "Posted By",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
          Delete
        </StyledTableCell>
        <StyledTableCell>
          View
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ fontWeight: "bold" }}> {headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function PostHistory({ id, postDate }) {
  const { cooperatorName, status, posts, admin } = useFetch();
  // React.useEffect(() => {
  //   getPosts();
  //   getCooperators();
  // }, []);
  const numberFormat = (val) => (
    <NumberFormat
      value={Number(val)}
      displayType="text"
      thousandSeparator={true}
    />
  );
  let rows;
  if (status === "loading") {
    rows = (
      <div>
        {/* <LinearProgress /> */}
        Loading...
        <CircularProgress color="success" />
      </div>
    );
  } else if (status === "succeeded") {
    //
    rows =
      id && postDate
        ? posts.filter(
            (post) =>
              Number(post.id) === Number(id) &&
              new Date(post.postDate).toLocaleString("default", {
                month: "long",
              }) +
                " " +
                new Date(post.postDate).getFullYear() ===
                postDate
          )
        : id
        ? posts.filter((post) => Number(post.id) === Number(id))
        : postDate
        ? posts.filter(
            (post) =>
              new Date(post.postDate).toLocaleString("default", {
                month: "long",
              }) +
                " " +
                new Date(post.postDate).getFullYear() ===
              postDate
          )
        : posts;
  }
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "95%" }}>
      {/* <ExportToCsv csvData={report} fileName="Monthly Report" /> */}
      {!id && postDate ? (
        <span style={{ fontSize: 13 }}>
          Filtering for <span style={{ color: "brown" }}>All</span>{" "}
        </span>
      ) : (
        id && (
          <span style={{ fontSize: 15 }}>
            <span>Personal Ledger for </span>{" "}
            <span style={{ color: "brown" }}>{cooperatorName(id)}</span>
          </span>
        )
      )}
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          background: "linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%)",
        }}
      >
        <TableContainer
          sx={{
            paddingTop: 2,
            maxHeight: 500,
            background: "linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%)",
            fontWeight: "bold",
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      // selected={isItemSelected}
                    >
                      <StyledTableCell>
                        {admin !== undefined && admin.role === "book keeper" && (
                          <Suspense fallback={<CircularProgress />}>
                            <DeleteOperation
                              postId={row.postId}
                              operation="post"
                              edge="end"
                            />
                          </Suspense>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                          <Suspense fallback={<CircularProgress />}>
                            <PostColView
                              postId={row.postId}
                            />
                          </Suspense>
                      </StyledTableCell>
                      <StyledTableCell>
                        {admin !== undefined && admin.role === "book keeper" && (
                          <Suspense fallback={<CircularProgress />}>
                            <span>
                              <PostLink
                                editPostId={row.postId}
                                editPostDate={row.postDate}
                                edge="end"
                              />
                            </span>
                          </Suspense>
                        )}
                      </StyledTableCell>

                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.id && cooperatorName(row.id)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.postDate).toLocaleDateString("pt-PT")}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.postParticuars}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {numberFormat(row.totalDeductions)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.sharesDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.sharesCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.sharesBal)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.savingsDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.savingsCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.savingsBal)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.rssDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.rssCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.rssBal)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.specialDepDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.specialDepCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.specialDepBal)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row.totalAsset}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.mainLoanDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.mainLoanCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.mainLoanBal)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Int: {numberFormat(row.mainLoanInt)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.softLoanDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.softLoanCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.softLoanBal)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Int: {numberFormat(row.softLoanInt)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.foodStuffDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.foodStuffCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.foodStuffBal)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Int: {numberFormat(row.foodStuffInt)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.welfareDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.welfareCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.welfareBal)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Int: {numberFormat(row.welfareInt)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.otherLoanDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.otherLoanCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.otherLoanBal)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Int: {numberFormat(row.otherLoanInt)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.buildingFundDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.buildingFundCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.buildingFundBal)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Int: {numberFormat(row.buildingFundInt)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.essentialCommodityDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.essentialCommodityCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.essentialCommodityBal)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.bheLoanDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.bheLoanCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.bheLoanBal)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* inner table row begins */}
                        <StyledTableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <StyledTableCell align="left">
                            Dr: {numberFormat(row.adminFessChargesDr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Cr: {numberFormat(row.adminFessChargesCr)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Bal: {numberFormat(row.adminFessChargesBal)}
                          </StyledTableCell>
                        </StyledTableRow>

                        {/* inner table row ends */}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {" "}
                        {numberFormat(row.developmentLevyCr)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {" "}
                        {numberFormat(row.entranceRegFeeCr)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.postedBy}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <StyledTableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <StyledTableCell colSpan={6} />
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
