import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useFetch from "./use-fetch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PostInner from "./post-inner";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        margin: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function PostNameMenu() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    newMainLoanAutoData,
    clearFieldInitialValue,
    isOnLoan,
    isObjectNotEmpty,
    setFieldInitialValue,
    cooperators,
    posts,
    status,
    error,
    resetNewLoanAutoData,
    updateNewMainLoanProcessing,
    updateAdditionalLoanProcessing,
    admin,
    fetchPostsAsync,
    users,
    fetchLoanEditAsync,
    fetchUsersAsync,
    loanEditList,
    toDecimal,
  } = useFetch();
  const lastPostDate = (id) => {
    try {
      return postLatest(id) !== undefined
        ? new Date(postLatest(id).postDate)
        : "None";
    } catch (error) {}
  };
  const loanMeta = (cooperatorId, loan, loanDate) =>
    loanEditList.find(
      (item) =>
        Number(item.cooperatorId) === Number(cooperatorId) &&
        item.loan === loan &&
        item.loanDate === loanDate
    );

  // const balOverCr = (bal, cr) => Number(toDecimal(Number(bal) / Number(cr), 0));
  const balOverCr = (bal, cr)=>{
    if (cr===0){ 
      return Number(bal)
    } else{
      return Number(toDecimal(Number(bal) / Number(cr), 0))
    }
  }
  const postLatest = (id) => {
    const idposts = posts.filter((post) => Number(post.id) === Number(id));
    const latestDate = new Date(
      Math.max(...idposts.map((elmt) => new Date(elmt.postDate)))
    ).toLocaleDateString("pt-PT");
    const latestPost = idposts.find(
      (post) =>
        new Date(post.postDate).toLocaleDateString("pt-PT") === latestDate
    );
    return latestPost;
  };

  const handleMenuItemClick = (id) => {
    const latestPost = postLatest(id);
    const initialValues = {
      id,
      postDate: new Date().toISOString().split("T")[0],
      postPrevDate: latestPost !== undefined ? latestPost.postDate : "",
      postParticuars:
        new Date().toLocaleString("default", { month: "long" }) +
        " Monthly Deduction",
      totalDeductions: 0,
      sharesDr: 0,
      sharesCr: 0,
      sharesBal:
        (latestPost !== undefined && Number(latestPost.sharesBal)) || 0,
      savingsCr: 0,
      savingsDr: 0,
      savingsBal:
        (latestPost !== undefined && Number(latestPost.savingsBal)) || 0,

      welfareCr: 0,
      welfareDr: 0,
      welfareBal:
      (latestPost !== undefined && Number(latestPost.welfareBal)) || 0,

      buildingFundCr: 0,
      buildingFundDr: 0,
      buildingFundBal:
      (latestPost !== undefined && Number(latestPost.buildingFundBal)) || 0,

      rssDr: 0,
      rssCr: 0,
      rssBal: (latestPost !== undefined && Number(latestPost.rssBal)) || 0,
      specialDepDr: 0,
      specialDepCr: 0,
      specialDepBal:
        (latestPost !== undefined && Number(latestPost.specialDepBal)) || 0,
      totalAsset:  (latestPost !== undefined && (Number(latestPost.sharesBal) + Number(latestPost.savingsBal) + Number(latestPost.rssBal))) || 0,  // Question to ask: is this the monthly total asset? Yes I think.
      mainLoanDr:
        latestPost !== undefined && Number(latestPost.mainLoanBal) > 0
          ? latestPost.mainLoanDr
          : 0,
      mainLoanCr:
        latestPost !== undefined && Number(latestPost.mainLoanBal) > 0
          ? loanMeta(latestPost.id, "main", latestPost.postDate) !== undefined
            ? loanMeta(latestPost.id, "main", latestPost.postDate).loanCr
            : latestPost.mainLoanCr
          : 0,
      mainLoanBal:
        latestPost !== undefined &&
        Number(latestPost.mainLoanBal) > 0 &&
        balOverCr(
          Number(latestPost.mainLoanBal),
          loanMeta(latestPost.id, "main", latestPost.postDate) !== undefined
            ? loanMeta(latestPost.id, "main", latestPost.postDate).loanCr
            : latestPost.mainLoanCr
        ) !== 1
          ? Number(latestPost.mainLoanBal) -
            (loanMeta(latestPost.id, "main", latestPost.postDate) !== undefined
              ? Number(
                  loanMeta(latestPost.id, "main", latestPost.postDate).loanCr
                )
              : Number(latestPost.mainLoanCr))
          : 0,
      mainLoanPrevBal:
        latestPost !== undefined && Number(latestPost.mainLoanBal) > 0
          ? Number(latestPost.mainLoanBal)
          : 0,
      mainLoanInt:
        latestPost !== undefined && Number(latestPost.mainLoanBal) > 0
          ? Number(latestPost.mainLoanInt)
          : 0,
      //(cooperatorId, loan, loanDate)
      // `loanEditId`, `cooperatorId`, `loan`, `loanOp`, `loanDate`, `loanCr`, `postPrevDate`

      softLoanDr:
        latestPost !== undefined && Number(latestPost.softLoanBal) > 0
          ? latestPost.softLoanDr
          : 0,
      softLoanCr:
        latestPost !== undefined && Number(latestPost.softLoanBal) > 0
          ? loanMeta(latestPost.id, "soft", latestPost.postDate) !== undefined
            ? loanMeta(latestPost.id, "soft", latestPost.postDate).loanCr
            : latestPost.softLoanCr
          : 0,
      softLoanBal:
        latestPost !== undefined &&
        Number(latestPost.softLoanBal) > 0 &&
        balOverCr(
          Number(latestPost.softLoanBal),
          loanMeta(latestPost.id, "soft", latestPost.postDate) !== undefined
            ? loanMeta(latestPost.id, "soft", latestPost.postDate).loanCr
            : latestPost.softLoanCr
        ) !== 1
          ? Number(latestPost.softLoanBal) -
            (loanMeta(latestPost.id, "soft", latestPost.postDate) !== undefined
              ? Number(
                  loanMeta(latestPost.id, "soft", latestPost.postDate).loanCr
                )
              : Number(latestPost.softLoanCr))
          : 0,
      softLoanPrevBal:
        latestPost !== undefined && Number(latestPost.softLoanBal) > 0
          ? Number(latestPost.softLoanBal)
          : 0,
      softLoanInt:
        latestPost !== undefined && Number(latestPost.softLoanBal) > 0
          ? Number(latestPost.softLoanInt)
          : 0,
      foodStuffDr:
        latestPost !== undefined && Number(latestPost.foodStuffBal) > 0
          ? latestPost.foodStuffDr
          : 0,
      foodStuffCr:
        latestPost !== undefined && Number(latestPost.foodStuffBal) > 0
          ? loanMeta(latestPost.id, "food", latestPost.postDate) !== undefined
            ? loanMeta(latestPost.id, "food", latestPost.postDate).loanCr
            : latestPost.foodStuffCr
          : 0,
      foodStuffBal:
        latestPost !== undefined &&
        Number(latestPost.foodStuffBal) > 0 &&
        balOverCr(
          Number(latestPost.foodStuffBal),
          loanMeta(latestPost.id, "food", latestPost.postDate) !== undefined
            ? loanMeta(latestPost.id, "food", latestPost.postDate).loanCr
            : latestPost.foodStuffCr
        ) !== 1
          ? Number(latestPost.foodStuffBal) -
            (loanMeta(latestPost.id, "food", latestPost.postDate) !== undefined
              ? Number(
                  loanMeta(latestPost.id, "food", latestPost.postDate).loanCr
                )
              : Number(latestPost.foodStuffCr))
          : 0,
      foodStuffPrevBal:
        latestPost !== undefined && Number(latestPost.foodStuffBal) > 0
          ? Number(latestPost.foodStuffBal)
          : 0,
      foodStuffInt:
        latestPost !== undefined && Number(latestPost.foodStuffBal) > 0
          ? Number(latestPost.foodStuffInt)
          : 0,
      // welfareDr:
      //   latestPost !== undefined && Number(latestPost.welfareBal) > 0
      //     ? latestPost.welfareDr
      //     : 0,
      // welfareCr:
      //   latestPost !== undefined && Number(latestPost.welfareBal) > 0
      //     ? loanMeta(latestPost.id, "welfare", latestPost.postDate) !==
      //       undefined
      //       ? loanMeta(latestPost.id, "welfare", latestPost.postDate).loanCr
      //       : latestPost.welfareCr
      //     : 0,
      // welfareBal:
      //   latestPost !== undefined &&
      //   Number(latestPost.welfareBal) > 0 &&
      //   balOverCr(
      //     Number(latestPost.welfareBal),
      //     loanMeta(latestPost.id, "welfare", latestPost.postDate) !== undefined
      //       ? loanMeta(latestPost.id, "welfare", latestPost.postDate).loanCr
      //       : latestPost.welfareCr
      //   ) !== 1
      //     ? Number(latestPost.welfareBal) -
      //       (loanMeta(latestPost.id, "welfare", latestPost.postDate) !==
      //       undefined
      //         ? Number(
      //             loanMeta(latestPost.id, "welfare", latestPost.postDate).loanCr
      //           )
      //         : Number(latestPost.welfareCr))
      //     : 0,
      // welfarePrevBal:
      //   latestPost !== undefined && Number(latestPost.welfareBal) > 0
      //     ? Number(latestPost.welfareBal)
      //     : 0,
      // welfareInt:
      //   latestPost !== undefined && Number(latestPost.welfareBal) > 0
      //     ? Number(latestPost.welfareInt)
      //     : 0,
      otherLoanDr:
        latestPost !== undefined && Number(latestPost.otherLoanBal) > 0
          ? latestPost.otherLoanDr
          : 0,
      otherLoanCr:
        latestPost !== undefined && Number(latestPost.otherLoanBal) > 0
          ? loanMeta(latestPost.id, "other", latestPost.postDate) !== undefined
            ? loanMeta(latestPost.id, "other", latestPost.postDate).loanCr
            : latestPost.otherLoanCr
          : 0,
      otherLoanBal:
        latestPost !== undefined &&
        Number(latestPost.otherLoanBal) > 0 &&
        balOverCr(
          Number(latestPost.otherLoanBal),
          loanMeta(latestPost.id, "other", latestPost.postDate) !== undefined
            ? loanMeta(latestPost.id, "other", latestPost.postDate).loanCr
            : latestPost.otherLoanCr
        ) !== 1
          ? Number(latestPost.otherLoanBal) -
            (loanMeta(latestPost.id, "other", latestPost.postDate) !== undefined
              ? Number(
                  loanMeta(latestPost.id, "other", latestPost.postDate).loanCr
                )
              : Number(latestPost.otherLoanCr))
          : 0,
      otherLoanPrevBal:
        latestPost !== undefined && Number(latestPost.otherLoanBal) > 0
          ? Number(latestPost.otherLoanBal)
          : 0,
      otherLoanInt:
        latestPost !== undefined && Number(latestPost.otherLoanBal) > 0
          ? Number(latestPost.otherLoanInt)
          : 0,
      // buildingFundDr:
      //   latestPost !== undefined && Number(latestPost.buildingFundBal) > 0
      //     ? latestPost.buildingFundDr
      //     : 0,
      // buildingFundCr:
      //   latestPost !== undefined && Number(latestPost.buildingFundBal) > 0
      //     ? loanMeta(latestPost.id, "building", latestPost.postDate) !==
      //       undefined
      //       ? loanMeta(latestPost.id, "building", latestPost.postDate).loanCr
      //       : latestPost.buildingFundCr
      //     : 0,
      // buildingFundBal:
      //   latestPost !== undefined &&
      //   Number(latestPost.buildingFundBal) > 0 &&
      //   balOverCr(
      //     Number(latestPost.buildingFundBal),
      //     loanMeta(latestPost.id, "building", latestPost.postDate) !== undefined
      //       ? loanMeta(latestPost.id, "building", latestPost.postDate).loanCr
      //       : latestPost.buildingFundCr
      //   ) !== 1
      //     ? Number(latestPost.buildingFundBal) -
      //       (loanMeta(latestPost.id, "building", latestPost.postDate) !==
      //       undefined
      //         ? Number(
      //             loanMeta(latestPost.id, "building", latestPost.postDate)
      //               .loanCr
      //           )
      //         : Number(latestPost.buildingFundCr))
      //     : 0,
      // buildingFundPrevBal:
      //   latestPost !== undefined && Number(latestPost.buildingFundBal) > 0
      //     ? Number(latestPost.buildingFundBal)
      //     : 0,
      // buildingFundInt:
      //   latestPost !== undefined && Number(latestPost.buildingFundBal) > 0
      //     ? Number(latestPost.buildingFundInt)
      //     : 0,
      essentialCommodityDr:
        latestPost !== undefined && Number(latestPost.essentialCommodityBal) > 0
          ? latestPost.essentialCommodityDr
          : 0,
      essentialCommodityCr:
        latestPost !== undefined && Number(latestPost.essentialCommodityBal) > 0
          ? loanMeta(latestPost.id, "essential", latestPost.postDate) !==
            undefined
            ? loanMeta(latestPost.id, "essential", latestPost.postDate).loanCr
            : latestPost.essentialCommodityCr
          : 0,
      essentialCommodityBal:
        latestPost !== undefined &&
        Number(latestPost.essentialCommodityBal) > 0 &&
        balOverCr(
          Number(latestPost.essentialCommodityBal),
          loanMeta(latestPost.id, "essential", latestPost.postDate) !==
            undefined
            ? loanMeta(latestPost.id, "essential", latestPost.postDate).loanCr
            : latestPost.essentialCommodityCr
        ) !== 1
          ? Number(latestPost.essentialCommodityBal) -
            (loanMeta(latestPost.id, "essential", latestPost.postDate) !==
            undefined
              ? Number(
                  loanMeta(latestPost.id, "essential", latestPost.postDate)
                    .loanCr
                )
              : Number(latestPost.essentialCommodityCr))
          : 0,
      essentialCommodityPrevBal:
        latestPost !== undefined && Number(latestPost.essentialCommodityBal) > 0
          ? Number(latestPost.essentialCommodityBal)
          : 0,
      essentialCommodityInt:
        latestPost !== undefined && Number(latestPost.essentialCommodityBal) > 0
          ? Number(latestPost.essentialCommodityInt)
          : 0,
      bheLoanDr:
        latestPost !== undefined && Number(latestPost.bheLoanBal) > 0
          ? latestPost.bheLoanDr
          : 0,
      bheLoanCr:
        latestPost !== undefined && Number(latestPost.bheLoanBal) > 0
          ? loanMeta(latestPost.id, "bhe", latestPost.postDate) !== undefined
            ? loanMeta(latestPost.id, "bhe", latestPost.postDate).loanCr
            : latestPost.bheLoanCr
          : 0,
      bheLoanBal:
        latestPost !== undefined &&
        Number(latestPost.bheLoanBal) > 0 &&
        balOverCr(
          Number(latestPost.bheLoanBal),
          loanMeta(latestPost.id, "bhe", latestPost.postDate) !== undefined
            ? loanMeta(latestPost.id, "bhe", latestPost.postDate).loanCr
            : latestPost.bheLoanCr
        ) !== 1
          ? Number(latestPost.bheLoanBal) -
            (loanMeta(latestPost.id, "bhe", latestPost.postDate) !== undefined
              ? Number(
                  loanMeta(latestPost.id, "bhe", latestPost.postDate).loanCr
                )
              : Number(latestPost.bheLoanCr))
          : 0,
      bheLoanPrevBal:
        latestPost !== undefined && Number(latestPost.bheLoanBal) > 0
          ? Number(latestPost.bheLoanBal)
          : 0,
      bheLoanInt:
        latestPost !== undefined && Number(latestPost.bheLoanBal) > 0
          ? Number(latestPost.bheLoanInt)
          : 0,
      adminFessChargesDr: 0,
      adminFessChargesCr: 0,
      adminFessChargesBal: 0,
      developmentLevyCr: 0,
      entranceRegFeeCr: latestPost !== undefined && 0 && 0,
      postedBy: admin.role,
    };
    dispatch(setFieldInitialValue(initialValues));
    dispatch(resetNewLoanAutoData());
    dispatch(updateNewMainLoanProcessing(false));
    return handleClose();
  };
  const handlleRefresh = () => {
    dispatch(updateNewMainLoanProcessing(false));
    dispatch(updateAdditionalLoanProcessing(false));
    dispatch(clearFieldInitialValue());
    dispatch(resetNewLoanAutoData());
    dispatch(fetchPostsAsync());
    !users.length && dispatch(fetchUsersAsync());
    return dispatch(fetchLoanEditAsync());
  };
  return (
    <Stack
      direction="column"
      spacing={{ xs: 1, sm: 2 }}
      alignItems="center"
      justifyContent="center"
    >
      {status === "failed" && (
        <h5
          style={{
            color: "red",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 25,
            paddingRight: 25,
            background: "rgba(240, 57, 57, 0.479)",
          }}
        >
          Something Went Wrong ... {error}
        </h5>
      )}
      {status === "loading" && (
        <h5
          style={{
            color: "rgba(3, 56, 10)",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 30,
            paddingRight: 30,
            background: "rgba(161, 235, 171, 0.479)",
          }}
        >
          ...loading
          {/* <CircularProgress /> */}
        </h5>
      )}
      <Box sx={{ my: 2 }}>
        <Box sx={{ display: "flex" }}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="outlined"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            style={{
              // width: 300,
              margin: "auto",
              textTransform: "none",
              marginBottom: 0,
            }}
          >
            Select Cooperator's Name
          </Button>

          {/* <Tooltip title="Reset the list">
            <IconButton onClick={handlleRefresh} sx={{ ml: 3 }}>
              <RefreshIcon />
            </IconButton>
          </Tooltip> */}
        </Box>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {cooperators.map(({ id, fname, mname, lname }) => (
            <MenuItem
              key={id}
              onClick={() => handleMenuItemClick(id)}
              disableRipple
              sx={{
                backgroundColor:
                new Date(lastPostDate(id)).toLocaleString("default", {
                  month: "long",
                }) ==
                new Date().toLocaleString("default", {
                  month: "long",
                })
                    ? "#e6ffe6"
                    : "#ffe6e6",
                    color:
                    new Date(lastPostDate(id)).toLocaleString("default", {
                      month: "long",
                    }) ==
                    new Date().toLocaleString("default", {
                      month: "long",
                    })
                    ? "#003300"
                    : "#660000",
              }}
            >
               <Tooltip
                title={`Last post date: ${lastPostDate(id)}`}
                placement="right"
              >
                <span>{fname + " " + mname + " " + lname + "  "}</span>
              </Tooltip>
            </MenuItem>
          ))}

          {status === "failed" && (
            <h6
              style={{
                color: "rgba(240, 57, 57, 0.479)",
                fontWeight: "bold",
                margin: 0,
                padding: 5,
              }}
            >
              Something Went Wrong. {error}
            </h6>
          )}
        </StyledMenu>
      </Box>
      <PostInner postOp="new" />
    </Stack>
  );
}
