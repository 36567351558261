import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Exco({ imgSrc, name }) {
  return (
    <Box sx={{ textAlign: "center" }}>
      <img
        src={imgSrc} //"/c003.jpg"
        alt="president"
        style={{
          objectFit: "contain",
          height: 340,
          width: 340,
          borderRadius: 45,
          border: "solid 2px pink",
        }}
      />
      <Typography sx={{ color: "text.secondary" }}>{name}</Typography>
      {/* <h3 style={{ margin: 0 }}>{name}</h3> */}
    </Box>
  );
}
// Exco.propType={imgSrc:PropType.string.required}
