import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CooperatorCore from "./cooperator-core";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CoorperatorNew() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  //   console.log(checked);
  //   //if checked is false, on submit close
  //   !checked && setOpen(false);
  // };
  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        style={{
          background: "#0C2340",
          textTransform: "none",
        }}
        startIcon={<PersonAddAltOutlinedIcon />}
      >
        Add New Cooperator
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            background: "#ADD8E6",
          }}
        >
          <EditIcon style={{ margin: 10, marginBottom: -5 }} />
          {"New Cooperator"}{" "}
          <Button onClick={handleClose} sx={{ float: "right" }}>
            Close
          </Button>
        </DialogTitle>
        <DialogContent
          style={{
            background:
              "linear-gradient(135deg, rgba(0,127,128,0.47942927170868344) 7%, rgba(118,148,216,0.4962359943977591) 35%, rgba(162,231,227,0.4990371148459384) 60%, rgba(229,255,239,1) 93%)",
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <CooperatorCore operaton="new" />
          </DialogContentText>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={checked ? "On Submit Close" : "On Submit Don't Close"}
          /> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
